@import "vars";
@import "smart-grid";

#category-content {
  min-height: 380px;

  .buttons {
    text-align: right;
  }
  .button-default {

    .from(1024px, { &:hover a {
      color: @textBlack;
    } });

    .md-block({ &:active a, &:focus {
      color: #ffffff;
    } });

    a {
      color: #ffffff;

      .md-block({ color: @dark; })
    }
  }
}

#category-description {
  margin-top: 20px;

  .img-wrapper {
    text-align: center;
    margin-bottom: 20px;
  }
}

.category {
  &-main {
    background-color: @lightGrey;
    border-bottom: 4px solid @textGrey;
    padding-bottom: 10px;
  }

  &-name {
    font-weight: 700;
    margin-left: 40px;
  }

  &-sort {
    .col();
    .size(2);
    .size-md(4);
    .size-xs(6);

    .control-label {
      margin-left: 10px;
    }
  }

  &-per-page {
    .col();
    .size(2);
    .size-md(4);
    .size-xs(6);

    .control-label {
      margin-left: 10px;
    }
  }

  &-product {
    &-item {
      .col();
      .size(3);
      .size-md(4);
      .size-sm(6);

      background-color: #ffffff;
      position: relative;
      margin-bottom: 30px;

      .from(1024px, { &:hover .category-button {
        display: block;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
      } });
      .from(1024px, { &:hover {
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
      } });
      .to(480px, { width: 100%; })
    }

    &-thumb {
      text-align: center;
      padding: 40px 10px 30px;

      .md-block({ padding: 20px 10px; });
    }


    &-row {
      .category-product {
        &-item {
          .size(4);
          .size-md(6);
          .size-xxs(12);
        }

        &-thumb {
          padding: 15px 15px 30px;
        }
      }
    }

    &-link {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .md-block({ display: none; })
    }
  }

  &-caption {
    h4 > a {
      .fz(16);
      color: @dark;

      .xs-block({ .fz(14); line-height: 1.4; });
    }
  }

  &-price {
    .fz(24);
    font-weight: 700;
    line-height: normal;

    .price {
      &-new {
        .fz(24);
        font-weight: 700;
        line-height: normal;
        margin-right: 10px;
      }

      &-old {
        .fz(18);
        font-weight: 600;
        text-decoration: line-through;
        text-decoration-color: red;
      }
    }
  }

  &-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(34, 53, 85, 0.7);
    display: none;

    .md-block({ position: relative; height: auto; background: none; padding-top: 0; display: block; });

    &-overlay {
      position: absolute;
      top: 40%;
      width: 100%;

      .md-block({ position: unset; });
    }

    .button {
      &-cat {
        background: transparent;
        border: 1px solid #ffffff;
        color: #ffffff;
        margin: auto auto 20px;
        width: 70%;
        font-weight: 500;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24/1920*100*1vw;

        &:active {
          box-shadow: 0 4px 20px rgba(56, 160, 246, 0.25);
          background-color: #283963;
          color: #ffffff;
        }
        &:active a {
          color: #ffffff;
        }

        .md-block({ &:active a, &:focus {
          color: #ffffff;
        }; border-color: @dark; margin-bottom: 10px; font-size: 18/1024*100*1vw; });
        .sm-block({ .fz(16) });
        .xs-block({ .fz(14); width: 100%; padding: 8px 24px; });

        .from(1024px, { &:hover {
          background: #ffffff;
          color: @textBlack;
        } &:hover a {
            color: @textBlack;
          } &:hover .disabled-icon {
              fill: @textBlack;
            } });

        a {
          color: #ffffff;
          font-weight: 500;

          .md-block({ color: @dark; });
        }

        span{
          .md-block({ color: @dark; &:active, &:focus{
            color: #ffffff;}})
        }

        .disabled-icon {
          fill: #fff;
          min-width: 24px;
          max-height: 24px;

          .md-block({ fill: @dark })
        }
      }

      &-disabled {
        .md-block({ background-color: @textGrey; &:active, &:focus {
          background-color: @textGrey;
          color: @dark;
        };
          &:active span, &:focus span{
            color: @dark;
          }});

        span {
          margin-right: 5px;

          .md-block({ color: @dark; });
        }
      }
    }

    &-product {
      .button {
        &-cat {
          color: #ffffff;
          margin: auto;
          width: 70%;
          font-weight: 500;
          line-height: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18/1920*100*1vw;

          &:active {
            box-shadow: 0 4px 20px rgba(56, 160, 246, 0.25);
            color: #ffffff;
            outline: none;
          }

          &:hover {
            background-color: darken(@sky, 20%);
          }

          .lg-block({ width: 100%; });
          .md-block({ font-size: 20/1024*100*1vw; });
          .sm-block({ .fz(16) });
          .xs-block({ .fz(14); width: 100%; padding: 8px 24px; });

          .cart-icon {
            width: 28px;
            height: 28px;
            fill: #fff;
            margin-right: 10px;

            .from-to(361px, 480px, { display: none; });
            .xxs-block({ width: 24px; height: 24px; });
          }
        }

        &-disabled {
          font-size: 18/1920*100*1vw;
          font-weight: 500;
          line-height: normal;
          margin: 0 auto;
          justify-content: center;

          .lg-block({ width: 100%; });
          .md-block({ font-size: 20/1024*100*1vw; });
          .sm-block({ .fz(16) });
          .xs-block({ width: 100%; padding: 8px 24px; });

          span {
            margin-right: 10px;
          }

          .disabled-icon {
            fill: @preHeaderBg;
            width: 28px;
            height: 28px;

            .from-to(361px, 480px, { display: none; });
            .xxs-block({ width: 24px; height: 24px; });
          }
        }
      }
    }
  }

  &-pagination, &-results {
    .col();
    .size(12);
  }

  &-info {
    &-description {
      height: 120px;
      overflow: hidden;
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20px;
        background-image: linear-gradient(to bottom, transparent, @lightGrey);
      }

      &.open {
        height: auto;
      }

      p {
        margin-bottom: 20px;
        .fz(14);
        line-height: 22px;
      }

      ul {
        .to(480px, { padding-left: 20px; });

        li {
          .fz(14);
          line-height: 22px;
          margin-bottom: 10px;
        }
      }
    }

    &-wrapper {
      .button-open {
        position: relative;
        height: 20px;

        &:before {
          content: '';
          width: 15px;
          height: 15px;
          border-top: 2px solid @darkBlue;
          border-left: 2px solid @darkBlue;
          display: block;
          transform: rotate(225deg);
          cursor: pointer;
          position: absolute;
          top: 0;
          right: 0;

        }

        &.open {
          &:before {
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}