@import "vars";
@import "smart-grid";

.breadcrumb {
  padding: 20px 0;
  list-style: none;
  display: inline-block;

  li {
    display: inline-block;
    margin-right: 10px;

    &:not(:last-child) {
      &:after {
        content: '';
        display: inline-block;
        margin-left: 5px;
        width: 8px;
        height: 8px;
        border-top: 1px solid @textGrey;
        border-right: 1px solid @textGrey;
        transform: rotate(45deg);
      }
    }

    a {
      .fz(14);
      color: @textGrey;

      .from(1024px,{&:hover{color:@dark;}});
    }
  }
}