/*fonts*/

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WRhzg.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gTD-w.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gTD-w.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gTD-w.ttf) format('truetype');
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oswald/v26/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvsUZiYA.ttf) format('truetype');
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/oswald/v26/TK3_WkUHHAIjg75cFRf3bXL8LICs1xZosUZiYA.ttf) format('truetype');
}

/*colors*/

/*placeholder*/

input[type="text"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #cecece;
}

input[type="text"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
input[type="number"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
textarea:-moz-placeholder {
  color: #cecece;
}

input[type="text"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="number"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
textarea::-moz-placeholder {
  color: #cecece;
}

input[type="text"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #cecece;
}

/* em */

/* box-shadow */

.pre-header {
  background-color: #4B4C4C;
  text-align: center;
  padding: 13px 0;
}

.pre-header.light-coloured {
  border-bottom: 1px solid #A5AEBE;
}

.pre-header > a {
  color: #ffffff;
  font-size: 0.875rem;
  margin-right: 46px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
}

.pre-header .user-icon {
  width: 16px;
  height: 16px;
  fill: #fff;
  margin-right: 6px;
}

.pre-header .account {
  display: inline-block;
  text-align: left;
}

.pre-header .account > a {
  color: #ffffff;
  font-size: 0.875rem;
  margin-right: 46px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
}

.pre-header .account-text {
  text-transform: lowercase;
  position: relative;
}

.pre-header .dropdown-menu > li > a {
  width: 100%;
}

.pre-header .dropdown-menu-left {
  border-radius: 0;
  background-color: #171717;
  top: 32px;
  border: 1px solid #fff;
  padding-top: 10px;
}

.pre-header .dropdown-menu-left li {
  text-align: center;
  margin: 0 10px 8px;
}

.pre-header .dropdown-menu-left a {
  color: #ffffff;
  border-bottom: 2px solid transparent;
  display: inline;
  padding: 0;
}

.pre-header .dropdown-menu-left a:hover {
  background: transparent;
  border-bottom: 2px solid #38A0F6;
}

.pre-header #search {
  margin-bottom: 0;
  z-index: 1;
}

.pre-header #search-input {
  border: none;
  border-bottom: 1px solid transparent;
  padding: 5px 10px;
  -webkit-appearance: none;
  box-shadow: 0 1px 6px #A5AEBE;
  border-radius: 30px;
  width: 720px;
  box-sizing: border-box;
}

.pre-header #search-input:focus {
  outline: none;
  border-bottom-color: #171717;
}

.pre-header .search-wrapper {
  display: inline-block;
  position: relative;
}

.pre-header .search-icon-item {
  position: relative;
  cursor: pointer;
}

.pre-header .search-icon-item svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}

.pre-header .search-input-wrapper {
  display: none;
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 5;
}

.light-coloured {
  background-color: #fff;
}

.light-coloured > a {
  color: #A5AEBE;
}

.light-coloured .user-icon {
  fill: #A5AEBE;
}

.light-coloured .account-text {
  color: #A5AEBE;
}

.light-coloured .dropdown-menu-left {
  background-color: #fff;
}

.light-coloured .dropdown-menu-left li > a:hover {
  color: #000;
}

.light-coloured .dropdown-menu-left li a {
  color: #000;
}

.light-coloured .search-icon-item svg {
  fill: #A5AEBE;
}

header {
  background: #171717;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
}

header .d-flex {
  align-items: center;
  justify-content: space-between;
}

.logo-gtr,
.logo_gtr {
  fill: #fff;
  width: 50px;
  height: 50px;
}

.burger {
  display: none;
  width: 40px;
  height: 40px;
  position: relative;
  align-items: center;
}

.burger-item {
  width: 80%;
  height: 4px;
  background-color: #171717;
  cursor: pointer;
  transition: all 0.2s;
  display: block;
}

.burger-item:before,
.burger-item:after {
  content: '';
  width: 100%;
  height: 4px;
  background-color: #171717;
  cursor: pointer;
  transition: all 0.2s;
  display: block;
}

.burger-item:before {
  transform: translateY(-10px);
}

.burger-item:after {
  transform: translateY(7px);
}

.burger_active .burger-item {
  background: transparent;
}

.burger_active .burger-item:before {
  transform: translateY(0) rotate(45deg);
}

.burger_active .burger-item:after {
  transform: translateY(-4px) rotate(-45deg);
}

.dp:after {
  content: '...';
  display: block;
  color: #ffffff;
  font-size: 1.5rem;
  letter-spacing: 3px;
  margin: 0;
  padding: 0;
  line-height: 0;
  text-align: center;
}

#menu {
  width: 100%;
  padding: 0 20px;
}

.header-menu {
  color: #ffffff;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  /*active menu*/
}

.header-menu a,
.header-menu span {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: normal;
}

.header-menu span {
  cursor: pointer;
}

.header-menu-drop {
  background-color: #fff;
  display: none;
  position: absolute;
  top: 86px;
  left: 0;
  z-index: 3;
  width: 100vw;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.header-menu-drop-wrapper {
  max-width: 1440px;
  margin: 0 auto;
  background-color: #ffffff;
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
}

.header-menu-item,
.header-menu-info {
  list-style: none;
  padding: 0;
  color: #000;
  margin-right: 55px;
}

.header-menu-first-category {
  display: none;
}

.header-menu.active-menu {
  display: block;
  position: absolute;
  top: 86px;
  left: 0;
  z-index: 3;
  padding: 10px;
  background-color: #171717;
  width: 100%;
  text-align: center;
}

.header-menu.active-menu .header-menu-item,
.header-menu.active-menu .header-menu-info {
  margin-bottom: 20px;
}

.header-menu.active-menu .header-menu-item.dp:after {
  position: absolute;
  top: 28px;
  left: 48.5%;
  font-size: 2rem;
  color: #38A0F6;
}

.header-menu.active-menu .header-menu-first-category {
  font-size: 1.25rem;
  color: #38A0F6;
}

.header-menu.active-menu .header-menu-drop {
  position: unset;
  display: block;
  box-shadow: none;
  width: auto;
  margin-top: 24px;
}

.header-menu.active-menu .header-menu-drop-wrapper {
  background-color: #171717;
  padding: 0;
}

.header-menu.active-menu .header-menu-drop .header-sub-menu {
  display: block;
  text-align: center;
}

.header-menu.active-menu .header-menu-drop .header-sub-menu-item {
  width: auto;
  margin: 0;
  padding: 0;
  display: block;
}

.header-menu.active-menu .header-menu-drop .header-sub-menu-item a {
  font-size: 1rem;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
}

.header-menu.active-menu .header-menu-drop .header-sub-menu-item a:hover {
  text-decoration: underline;
}

.header-menu.active-menu .header-menu-drop .header-sub-menu-item .img-wrapper {
  display: none;
}

.header-menu.active-menu .header-menu-drop .header-sub-menu-item.all-category {
  display: none;
}

.header-menu.active-menu .header-menu-info a {
  font-size: 1.25rem;
}

.header-menu.active-menu .header-menu-info a:hover {
  text-decoration: underline;
}

.header-sub-menu {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 0;
}

.header-sub-menu-item {
  box-sizing: border-box;
  list-style: none;
  text-align: center;
  width: calc(100% / 6 - 40px);
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 20px;
}

.header-sub-menu-item .img-wrapper {
  margin: 10px auto 5px;
}

.header-sub-menu-item a {
  font-size: 1.375rem;
  text-transform: unset;
  color: #000;
  line-height: normal;
}

.header-sub-menu-item.all-category a {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.header-sub-menu-item.all-category span {
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: unset;
  color: #000;
  display: block;
}

.header-sub-menu-item .dot {
  width: 5px;
  height: 5px;
  background-color: #000;
  border-radius: 50%;
  display: block;
  position: relative;
  margin-top: 20px;
}

.header-sub-menu-item .dot:after,
.header-sub-menu-item .dot:before {
  content: '';
  width: 5px;
  height: 5px;
  background-color: #000;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 0;
}

.header-sub-menu-item .dot:after {
  left: 10px;
}

.header-sub-menu-item .dot:before {
  left: -10px;
}

.header-shopping-cart {
  position: relative;
}

.header-shopping-cart a {
  font-weight: 600;
  color: #ffffff;
}

.header-shopping-cart a:hover {
  color: #ffffff;
}

.header-shopping-cart .cart-icon {
  fill: #fff;
  width: 40px;
  height: 40px;
  position: relative;
}

.header-shopping-cart span {
  position: absolute;
  top: 7px;
  left: 14px;
  right: auto;
  display: block;
  width: 20px;
  height: 20px;
  text-align: center;
}

header.light-coloured {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

header.light-coloured .logo-gtr,
header.light-coloured .logo_gtr {
  fill: #171717;
}

header.light-coloured .header-menu a,
header.light-coloured .header-menu span {
  color: #171717;
}

header.light-coloured .header-menu.active-menu a,
header.light-coloured .header-menu.active-menu span {
  color: #fff;
}

header.light-coloured .dp:after {
  color: #171717;
}

header.light-coloured .header-shopping-cart .cart-icon {
  fill: #171717;
}

header.light-coloured .header-shopping-cart #cart-total {
  color: #171717;
}

.header-lang {
  position: relative;
  margin-left: 20px;
}

.lang-button {
  background: transparent;
  border: none;
  position: relative;
  outline: none;
  z-index: 3;
}

.lang-button span {
  text-transform: uppercase;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
}

.lang-button.dp:after {
  left: 20%;
}

.lang-list {
  position: absolute;
  top: 34px;
  left: -7px;
  padding: 13px;
  list-style: none;
  text-align: center;
  text-transform: uppercase;
  display: none;
  background-color: #171717;
  z-index: 3;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.lang-list li:not(:last-child) {
  margin-bottom: 10px;
}

.lang-list a {
  color: #ffffff;
  border-bottom: 2px solid transparent;
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;
}

.lang-list a:hover,
.lang-list a:focus {
  border-bottom: 2px solid #38A0F6;
}

.lang-list a:active {
  border-bottom: 2px solid #fff;
}

.lang-list.open {
  display: block;
}

.lang-overlay {
  display: none;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 2;
}

.light-coloured .lang-button span {
  color: #171717;
}

.light-coloured .lang-list {
  background-color: #ffffff;
}

.light-coloured .lang-list li a {
  color: #171717;
}

/****** OWL HOME ******/

.home-gtr {
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 64px;
}

/****** CATEGORY MODULE HOME ******/

.category-module {
  margin-bottom: 60px;
}

.category-module h3 {
  display: none;
}

.category-module-items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.category-module-item {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(33.333333333333336% - 30px);
  background-color: #F5F5F5;
  text-align: center;
  padding: 25px 25px 29px;
  transition: ease 0.5s;
}

.category-module-item:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  transition: ease 0.5s;
}

.category-module-item a {
  color: #000;
  font-size: 1.5rem;
  font-weight: 700;
  display: block;
}

.category-module-item .img-wrapper {
  margin: 23px auto 0;
  max-width: 385px;
}

.home-product-items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.home-product-item {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(33.333333333333336% - 30px);
  position: relative;
  transition: 1s ease;
  padding: 10px;
  background-color: #F5F5F5;
  margin-bottom: 30px;
}

.home-product-item:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  transform: translateY(20px);
}

.home-product-item:hover .img-wrapper {
  transition: 1s ease;
  filter: blur(10px);
  transform: scale(0.9);
  opacity: 0.7;
}

.home-product-item:hover .home-product-info {
  opacity: 1;
  transition: 1s ease;
}

.home-product-item:hover h2 {
  font-size: 1.125rem;
  transition: 1s;
}

.home-product-item h2 {
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}

.home-product-item .img-wrapper {
  transition: 1s ease;
  overflow: hidden;
  text-align: center;
}

.home-product-info {
  padding: 16px;
  position: absolute;
  top: 58px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: 1s ease;
}

.home-product-list {
  list-style: none;
  padding-left: 0;
  flex-basis: 320px;
}

.home-product-list ul {
  padding-left: 0;
}

.home-product-list li {
  list-style: none;
  margin-bottom: 10px;
  text-align: center;
  color: #171717;
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 500;
}

.home-product-button {
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
}

.home-product-button .button-default a {
  color: #ffffff;
}

.product-overview-menu {
  background-color: #000000;
  text-align: right;
  padding: 10px 0;
}

.product-overview-menu.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  z-index: 1;
}

.product-overview-menu-items {
  display: inline-block;
  padding-left: 0;
  margin: 0;
}

.product-overview-menu-item {
  display: inline-block;
}

.product-overview-menu-button {
  background: none;
  border: none;
  margin-right: 69px;
}

.product-overview-menu-button:active svg {
  fill: #38A0F6;
}

.product-overview-menu-button:active {
  color: #38A0F6;
  outline: none;
}

.product-overview-menu-button svg {
  width: 32px;
  height: 32px;
  display: block;
  fill: #fff;
  margin: 0 auto 10px;
}

.product-overview-menu-button span {
  font-size: 0.875rem;
  font-weight: 500;
  color: #ffffff;
}

.product-overview-menu-button.active svg {
  fill: #38A0F6;
}

.product-overview-menu-button.active span {
  color: #38A0F6;
}

.landing-page {
  padding: 0;
  margin: 0 auto;
  max-width: 1920px;
  border-bottom: 4px solid #a5aebe;
}

.landing-page img {
  line-height: 0;
}

.lp-wrapper {
  margin: 0 auto;
  max-width: 1720px;
  padding: 0;
  position: relative;
}

.lp-first-screen {
  padding: 1px 75px;
  background-position: top center;
  background-repeat: no-repeat;
  height: 900px;
}

.lp-first-screen .lp-h1 {
  text-align: right;
  margin-top: 147px;
}

.lp-second-screen {
  display: flex;
}

.lp-second-screen .lp-image {
  margin-top: 120px;
}

.lp-third-screen {
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 830px;
  padding-top: 1px;
}

.lp-fifth-screen,
.lp-sixth-screen,
.lp-fourth-screen {
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 830px;
  display: flex;
}

.lp-sixth-screen {
  min-height: 400px;
}

.lp-sixth-screen .lp-text-block {
  padding-top: 3%;
  margin-top: 0;
}

.lp-seventh-screen,
.lp-eighth-screen,
.lp-ninth-screen,
.lp-tenth-screen,
.lp-eleventh-screen {
  background-color: #000000;
  display: flex;
  min-height: 503px;
}

.lp-h1 {
  color: #E2E3E4;
  font-size: 6rem;
  font-weight: 700;
  text-align: center;
}

.lp-h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #F4F5F7;
  margin-bottom: 50px;
  margin-left: 60px;
}

.lp-image {
  width: 50%;
}

.lp-text {
  color: #f4f5f7;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 35px;
  max-width: 773px;
  margin-left: 60px;
}

.lp-text-block {
  margin-top: 120px;
  width: 50%;
  padding: 1px 0;
}

.lp-icon-items {
  margin-top: 80px;
  margin-right: 30px;
}

.lp-icon-items.horizontal {
  display: flex;
  padding-left: 5px;
}

.lp-icon-items.horizontal .lp-icon-item {
  width: 33.3%;
}

.lp-icon-items.vertical {
  text-align: center;
}

.lp-icon-items.vertical .lp-icon-item {
  margin: 4% auto 0 auto;
  width: 374px;
}

.lp-icon-item {
  text-align: center;
}

.lp-icon-item .check-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 35px;
}

.lp-icon-item .check-icon.red {
  fill: #F8082D;
}

.lp-icon-item span {
  display: block;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 29px;
}

.lp-where-buy {
  background-color: #000000;
  background-repeat: no-repeat;
  min-height: 545px;
  padding-top: 1px;
  background-position: 13% center;
  background-size: cover;
}

.lp-where-buy h1 {
  margin-top: 80px;
  margin-bottom: 60px;
  font-size: 4rem;
  line-height: 94px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
}

.lp-where-buy p {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 35px;
  max-width: 1200px;
  margin: 0 auto 60px;
  color: #ffffff;
  text-transform: uppercase;
}

.lp-where-buy p span {
  color: #FF423F;
}

.lp-button {
  text-align: center;
}

.lp-button .button-main {
  background-color: #FF423F;
  border: 1px solid #FF423F;
  padding: 25px 91px;
  transition: 0.05s ease;
}

.lp-button .button-main a {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 35px;
}

.lp-modal {
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  overflow-y: scroll;
  background-color: #fff;
  padding: 55px;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
}

.lp-modal-content #gallery .img-wrapper {
  text-align: center;
}

.lp-modal-content #gallery .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.lp-modal-content #gallery .owl-nav .owl-prev,
.lp-modal-content #gallery .owl-nav .owl-next {
  position: absolute;
  top: 50%;
}

.lp-modal-content #gallery .owl-nav .owl-prev span,
.lp-modal-content #gallery .owl-nav .owl-next span {
  font-size: 3rem;
  font-weight: 700;
  color: #666;
}

.lp-modal-content #gallery .owl-nav .owl-prev span:hover,
.lp-modal-content #gallery .owl-nav .owl-next span:hover {
  color: #888888;
}

.lp-modal-content #gallery .owl-nav .owl-prev {
  left: 10px;
}

.lp-modal-content #gallery .owl-nav .owl-next {
  right: 10px;
}

.lp-modal-content #gallery .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.lp-modal-content #gallery .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.lp-modal-content #gallery .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.lp-modal-content #gallery .owl-dots .owl-dot.active span,
.lp-modal-content #gallery .owl-dots .owl-dot:hover span {
  background: #869791;
}

#details {
  display: none;
}

#details #p_name {
  color: #171717;
}

#details .img-wrapper {
  text-align: center;
  margin-bottom: 40px;
}

#details .details-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

#details .details-wrapper .img-wrapper {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(41.66666666666667% - 30px);
}

#details .details-variants {
  margin-right: 20px;
  border-top: 1px solid #ccc;
  padding-top: 20px;
}

#details .details-block {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(58.333333333333336% - 30px);
  padding-right: 10px;
}

#details .details-block .variants-name {
  font-weight: 700;
}

#details .details-block #variants {
  display: flex;
}

#details .details-block #variants .vblock-item {
  padding: 15px;
  border: 1px dotted #171717;
  margin-right: 15px;
}

#details .details-block #variants .vblock-item:hover {
  border: 1px solid #171717;
}

#details .details-block #variants .vblock-item img {
  width: 50px;
  height: 50px;
  max-width: 100%;
  border-radius: 50%;
}

#details .details-icons-items {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  text-align: center;
}

#details .details-icons-items svg {
  width: 256px;
  height: 256px;
}

#details .details-icons-items .details-item {
  width: calc(100% / 3 - 20px);
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}

#details .details-icons-items .details-item .icon-wrapper {
  max-width: 150px;
  height: 150px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 0 auto;
}

#details .details-icons-items .details-item .icon-wrapper svg {
  width: 100%;
  height: 100%;
}

#details .details-icons-items .details-item .icon-wrapper .wheels-icon {
  width: 40%;
  height: 40%;
}

#details .details-icons-items .details-item span {
  display: block;
  text-align: center;
  font-size: 0.875rem;
}

#details .details-icon-item {
  width: 50%;
  text-align: center;
  margin-bottom: 20px;
}

#details .details-line {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  border-bottom: 1px solid #ccc;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  line-height: 1.2;
}

#details .details-name {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(33.333333333333336% - 30px);
  color: #666666;
  font-size: 1.625rem;
}

#details .details-value {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(66.66666666666667% - 30px);
  color: #666666;
  font-size: 1rem;
}

#details #general-atts {
  margin-top: 40px;
}

.lp-fix-menu .product-overview-menu-items {
  position: fixed;
  top: 20%;
  right: 18px;
  z-index: 1000;
  display: block;
  text-align: center;
  background-color: #ffffff;
}

.lp-fix-menu .product-overview-menu-item {
  display: block;
}

.lp-fix-menu .product-overview-menu-item svg {
  fill: #666;
}

.lp-fix-menu .product-overview-menu-item span {
  color: #666;
}

.lp-fix-menu-button {
  margin-bottom: 18px;
  background: none;
  border: none;
}

.lp-fix-menu-button svg {
  width: 32px;
  height: 32px;
  display: block;
  fill: #fff;
  margin: 0 auto 10px;
}

.lp-fix-menu-button span {
  font-size: 0.875rem;
  font-weight: 500;
  color: #ffffff;
}

.lp-fix-menu-button.active svg {
  fill: #38A0F6;
}

.lp-fix-menu-button.active span {
  color: #38A0F6;
}

.lp-fix-menu-button.x-close {
  position: relative;
  width: 50px;
  height: 50px;
  transition: 1s;
}

.lp-fix-menu-button.x-close:after,
.lp-fix-menu-button.x-close:before {
  content: '';
  position: absolute;
  top: 0;
  width: 1px;
  height: 50px;
  background-color: #666666;
}

.lp-fix-menu-button.x-close:after {
  transform: rotate(45deg);
}

.lp-fix-menu-button.x-close:before {
  transform: rotate(-45deg);
}

.breadcrumb {
  padding: 20px 0;
  list-style: none;
  display: inline-block;
}

.breadcrumb li {
  display: inline-block;
  margin-right: 10px;
}

.breadcrumb li:not(:last-child):after {
  content: '';
  display: inline-block;
  margin-left: 5px;
  width: 8px;
  height: 8px;
  border-top: 1px solid #A5AEBE;
  border-right: 1px solid #A5AEBE;
  transform: rotate(45deg);
}

.breadcrumb li a {
  font-size: 0.875rem;
  color: #A5AEBE;
}

#category-content {
  min-height: 380px;
}

#category-content .buttons {
  text-align: right;
}

#category-content .button-default a {
  color: #ffffff;
}

#category-description {
  margin-top: 20px;
}

#category-description .img-wrapper {
  text-align: center;
  margin-bottom: 20px;
}

.category-main {
  background-color: #F5F5F5;
  border-bottom: 4px solid #A5AEBE;
  padding-bottom: 10px;
}

.category-name {
  font-weight: 700;
  margin-left: 40px;
}

.category-sort {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(16.666666666666668% - 30px);
}

.category-sort .control-label {
  margin-left: 10px;
}

.category-per-page {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(16.666666666666668% - 30px);
}

.category-per-page .control-label {
  margin-left: 10px;
}

.category-product-item {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(25% - 30px);
  background-color: #ffffff;
  position: relative;
  margin-bottom: 30px;
}

.category-product-thumb {
  text-align: center;
  padding: 40px 10px 30px;
}

.category-product-row .category-product-item {
  width: calc(33.333333333333336% - 30px);
}

.category-product-row .category-product-thumb {
  padding: 15px 15px 30px;
}

.category-product-link {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.category-caption h4 > a {
  font-size: 1rem;
  color: #171717;
}

.category-price {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: normal;
}

.category-price .price-new {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: normal;
  margin-right: 10px;
}

.category-price .price-old {
  font-size: 1.125rem;
  font-weight: 600;
  text-decoration: line-through;
  text-decoration-color: red;
}

.category-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(34, 53, 85, 0.7);
  display: none;
}

.category-button-overlay {
  position: absolute;
  top: 40%;
  width: 100%;
}

.category-button .button-cat {
  background: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  margin: auto auto 20px;
  width: 70%;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25vw;
}

.category-button .button-cat:active {
  box-shadow: 0 4px 20px rgba(56, 160, 246, 0.25);
  background-color: #283963;
  color: #ffffff;
}

.category-button .button-cat:active a {
  color: #ffffff;
}

.category-button .button-cat a {
  color: #ffffff;
  font-weight: 500;
}

.category-button .button-cat .disabled-icon {
  fill: #fff;
  min-width: 24px;
  max-height: 24px;
}

.category-button .button-disabled span {
  margin-right: 5px;
}

.category-button-product .button-cat {
  color: #ffffff;
  margin: auto;
  width: 70%;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9375vw;
}

.category-button-product .button-cat:active {
  box-shadow: 0 4px 20px rgba(56, 160, 246, 0.25);
  color: #ffffff;
  outline: none;
}

.category-button-product .button-cat:hover {
  background-color: #096dbf;
}

.category-button-product .button-cat .cart-icon {
  width: 28px;
  height: 28px;
  fill: #fff;
  margin-right: 10px;
}

.category-button-product .button-disabled {
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: normal;
  margin: 0 auto;
  justify-content: center;
}

.category-button-product .button-disabled span {
  margin-right: 10px;
}

.category-button-product .button-disabled .disabled-icon {
  fill: #4B4C4C;
  width: 28px;
  height: 28px;
}

.category-pagination,
.category-results {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(100% - 30px);
}

.category-info-description {
  height: 120px;
  overflow: hidden;
  position: relative;
}

.category-info-description:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  background-image: linear-gradient(to bottom, transparent, #F5F5F5);
}

.category-info-description.open {
  height: auto;
}

.category-info-description p {
  margin-bottom: 20px;
  font-size: 0.875rem;
  line-height: 22px;
}

.category-info-description ul li {
  font-size: 0.875rem;
  line-height: 22px;
  margin-bottom: 10px;
}

.category-info-wrapper .button-open {
  position: relative;
  height: 20px;
}

.category-info-wrapper .button-open:before {
  content: '';
  width: 15px;
  height: 15px;
  border-top: 2px solid #223555;
  border-left: 2px solid #223555;
  display: block;
  transform: rotate(225deg);
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

.category-info-wrapper .button-open.open:before {
  transform: rotate(45deg);
}

#product-options {
  margin-top: 15px;
  margin-bottom: 50px;
}

#product-options .input-quantity {
  margin-bottom: 20px;
}

#product-options .product-group {
  align-items: flex-end;
}

#product.product-group {
  flex-wrap: wrap;
}

#tab-specification .table {
  width: 60%;
  font-size: 1rem;
}

#tab-specification .table thead tr > td {
  background-color: #F5F5F5;
  border: none;
}

#tab-specification .table tbody tr > td {
  border: none;
}

#tab-specification .table tbody tr > td:first-child {
  width: 28%;
}

#tab-specification .table tbody tr > td:last-child {
  font-weight: 600;
  text-align: right;
}

#tab-review {
  width: 60%;
}

#tab-review .buttons {
  text-align: center;
}

#tab-review .button-review {
  font-size: 1rem;
  color: #ffffff;
  text-transform: unset;
}

#tab-review .product-form-item {
  margin-bottom: 20px;
}

#tab-review .product-form-item input[type="text"],
#tab-review .product-form-item textarea[name="text"] {
  border: 2px solid #38A0F6;
  border-radius: 30px;
}

#tab-review .product-form-item textarea[name="text"] {
  padding: 10px 20px;
  resize: vertical;
}

#tab-review .product-form-item .control-label {
  color: #38A0F6;
  background-color: #ffffff;
  position: relative;
  top: 15px;
  left: 30px;
  padding: 0 10px;
}

#tab-review .product-form-item .control-label-rating {
  font-weight: 600;
}

.product-main {
  padding-bottom: 40px;
}

.product-name h1 {
  font-size: 1.875vw;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 30px;
}

.product-image {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.product-image-block {
  margin-bottom: 60px;
}

.product-image-main {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(50% - 30px);
}

.product-image-main .img-wrapper {
  background-color: #ffffff;
  max-width: 543px;
  margin: 0 auto;
}

.product-image-additional {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(50% - 30px);
}

.product-image-additional-items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.product-image-additional-item {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(50% - 30px);
  background-color: #ffffff;
  margin-bottom: 30px;
}

.product-image-additional-item .img-wrapper {
  max-width: 225px;
  padding: 10px 0;
  border: 2px solid transparent;
  box-sizing: border-box;
  margin: 0 auto;
}

.product-group {
  display: flex;
  justify-content: space-between;
  width: 63%;
  margin: 0 auto;
}

.product-price {
  margin-right: 5.72916667vw;
}

.product-price .list-unstyled {
  display: inline-block;
}

.product-price .list-unstyled li {
  display: inline-block;
}

.product-price .list-unstyled li h2 {
  font-size: 2.25rem;
  font-weight: 600;
}

.product-price .list-unstyled li span {
  font-size: 1.5rem;
  margin-right: 20px;
}

.product-price-preorder-text {
  color: #000;
}

.product-price-preorder-text span {
  color: #FF423F;
}

.product-buttons-buy {
  display: flex;
  margin-bottom: 20px;
  align-self: flex-end;
}

.product-buttons-buy label[for="input-quantity"] {
  display: none;
}

.product-buttons-buy input[name="quantity"] {
  display: none;
}

.product-buttons-buy .button-main {
  color: #ffffff;
  font-weight: 600;
  border: none;
  padding: 11px 30px;
}

.product-buttons-buy .button-buy {
  background-color: #38A0F6;
  display: flex;
  align-items: center;
  margin-right: 30px;
  transition: 0.5s ease;
}

.product-buttons-buy .button-buy span {
  margin-right: 30px;
}

.product-buttons-buy .button-buy .cart-icon {
  fill: #fff;
  width: 36px;
  height: 36px;
}

.product-buttons-buy .button-disabled span {
  margin-right: 0;
}

.product-buttons-buy .button-onclick {
  background-color: #135ACE;
  padding: 19px 30px;
  transition: 0.5s ease;
}

.product-buttons-buy .button-onclick a {
  color: #ffffff;
}

.product-variant {
  display: flex;
  align-items: flex-end;
  margin-right: 20px;
}

.product-variant .vp_title,
.product-variant .vbox {
  display: inline-block;
}

.product-variant .vp_title {
  margin-right: 40px;
  margin-top: 0;
}

.product-variant .vbox {
  margin-right: 5px;
  line-height: 0;
}

.product-variant .vblock {
  line-height: normal;
  display: inline-block;
}

.product-variant .vblock-item {
  border-radius: 50%;
  border: 1px solid #cccccc;
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: block;
}

.product-variant .vblock-item a {
  display: block;
  width: 40px;
  height: 40px;
}

.product-variant .vblock-item a img {
  max-width: 100%;
}

.product-stock {
  margin: 10px 0 0;
  padding: 0;
}

.product-stock .stock-name {
  display: none;
}

.product-stock .stock-value {
  font-size: 1.875rem;
  position: relative;
  line-height: normal;
  margin-left: 60px;
}

.product-stock .stock-value:before {
  content: '';
  width: 20px;
  height: 20px;
  display: block;
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  margin-right: 60px;
  top: 10px;
  left: -60px;
}

.product-stock .stock-value.in-stock:before {
  background-color: #18AF2E;
}

.product-stock .stock-value.out-stock:before {
  background-color: #A5AEBE;
}

.product-tab-wrapper {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(100% - 30px);
}

.product-tab-wrapper .nav.nav-tabs {
  border-bottom-color: #A5AEBE;
}

.product-tab-wrapper .nav.nav-tabs li {
  margin-bottom: 0;
  margin-right: 1.5625vw;
  text-align: center;
}

.product-tab-wrapper .nav.nav-tabs li a {
  position: relative;
  border: none;
  font-size: 1.5rem;
  color: #000;
  font-weight: 600;
}

.product-tab-wrapper .nav.nav-tabs li a:before {
  content: '';
  display: block;
  width: 100%;
  height: 5px;
  background-color: transparent;
  position: absolute;
  bottom: -3px;
  left: 0;
  border-radius: 10px;
}

.product-tab-wrapper .nav.nav-tabs li.active a {
  border: none;
}

.product-tab-wrapper .nav.nav-tabs li.active a:before {
  background-color: #38A0F6;
}

.product-advantages-item {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(25% - 30px);
  margin-bottom: 40px;
}

.product-advantages-text {
  background-color: #000;
  padding: 15px;
}

.product-advantages-text h3 {
  color: #ffffff;
  margin-top: 0;
  font-size: 1.375rem;
  font-weight: 700;
  text-transform: uppercase;
}

.product-advantages-text p {
  color: #ffffff;
  font-size: 1rem;
}

#tab-description p {
  font-size: 1rem;
  text-indent: 30px;
  font-weight: normal;
  color: #000000;
  line-height: 30px;
}

.description {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  padding: 11px 30px 40px;
}

.description h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #000000;
  margin-top: 0;
  text-indent: 30px;
}

.description-text {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(66.66666666666667% - 30px);
}

.description-image {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(33.333333333333336% - 30px);
  background-color: #ffffff;
}

.description-advantages-block {
  background-color: #F5F5F5;
  padding: 30px 30px 60px;
}

.description-advantages-block h2 {
  font-size: 2.25rem;
  font-weight: 600;
  margin-bottom: 40px;
  text-transform: uppercase;
}

.description-advantages-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.description-advantages-wrap h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #000000;
  margin-top: 0;
  text-indent: 30px;
}

.description-advantages-image {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(33.333333333333336% - 30px);
  margin-bottom: 30px;
  background-color: #ffffff;
}

.description-advantages-image.image-3 {
  width: calc(33.333333333333336% - 30px);
}

.description-advantages-text {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(33.333333333333336% - 30px);
  margin-bottom: 30px;
}

.description-advantages-text.long-text {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(66.66666666666667% - 30px);
}

.description-advantages-text.long-text h3 {
  margin-top: 0;
}

.description-advantages-img-text {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(33.333333333333336% - 30px);
  margin-bottom: 30px;
}

.description-advantages-img-text .img-wrapper {
  margin-bottom: 25px;
}

.description-parameters {
  padding-top: 60px;
  padding-bottom: 60px;
}

.description-parameters-wrap {
  border: 1px solid #FB8200;
  background-color: #FEF3E7;
  padding: 40px 60px;
  border-radius: 10px;
  margin: 0 auto;
  width: 90%;
}

.description-parameters h3 {
  font-size: 1.5rem;
  color: #fb8200;
  text-indent: 43px;
}

.description-parameters ul {
  padding-left: 0;
}

.description-parameters li {
  font-size: 1.125rem;
  list-style: none;
  color: #4a4b57;
  line-height: 40px;
  position: relative;
  text-indent: 20px;
}

.description-parameters li:before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #fb8200;
  margin-right: 10px;
}

.description-parameters li:after {
  content: '';
  display: block;
  width: 7px;
  height: 4px;
  border-left: 1px solid #fb8200;
  border-bottom: 1px solid #fb8200;
  position: absolute;
  top: 15px;
  left: 25px;
  transform: rotate(-45deg);
}

#button-preorder {
  margin-right: 0;
  margin-left: 20px;
}

#preorder {
  width: 70%;
  text-align: center;
}

#preorder-modal {
  display: none;
  position: fixed;
  top: 20%;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #ffffff;
  z-index: 10;
  max-width: 520px;
  padding: 15px;
  border-radius: 4px;
}

#preorder-modal .img-wrapper {
  width: 30%;
}

#preorder-modal .preorder-block {
  display: flex;
}

#preorder-modal .preorder-modal-close {
  text-align: center;
  font-size: 1.375rem;
  font-weight: 700;
  position: relative;
  color: #000;
}

#preorder-modal .preorder-modal-close span {
  cursor: pointer;
  position: absolute;
  width: 30px;
  height: 30px;
  top: -28px;
  right: -28px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  background-color: #171717;
  box-shadow: 0 0 3px #171717;
}

#preorder-modal .preorder-modal-close span:after,
#preorder-modal .preorder-modal-close span:before {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 3px;
  border-radius: 3px;
  background-color: #ffffff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

#preorder-modal .preorder-modal-close span:after {
  transform: rotate(45deg);
}

#preorder-modal .preorder-modal-close span:before {
  transform: rotate(-45deg);
}

#preorder-modal fieldset {
  width: 90%;
  margin: 0 auto;
}

#preorder-modal fieldset .page-form-item input,
#preorder-modal fieldset .page-form-item textarea {
  margin-top: 15px;
}

#preorder-modal fieldset .page-form-item textarea {
  resize: vertical;
  margin-bottom: 15px;
}

#preorder .error {
  border-color: #FF423F;
  box-shadow: 0 0 10px #FF423F;
}

.preorder-modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9;
}

img.mfp-img {
  background-color: #ffffff;
  padding: 0;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #171717;
  right: 2px;
}

.mfp-counter {
  right: 6px;
}

.product-image-mobile.owl-carousel {
  display: none;
}

.product-image-mobile.owl-carousel .img-wrapper {
  text-align: center;
}

.product-image-mobile.owl-carousel .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.product-image-mobile.owl-carousel .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.product-image-mobile.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.product-image-mobile.owl-carousel .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.product-image-mobile.owl-carousel .owl-dots .owl-dot.active span,
.product-image-mobile.owl-carousel .owl-dots .owl-dot:hover span {
  background: #869791;
}

.product-modal {
  display: none;
  position: fixed;
  top: 12vw;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  max-width: 820px;
  z-index: 11;
  overflow: hidden;
  padding: 0 10px;
}

.product-modal.active {
  display: block;
}

.product-modal-close {
  border: 2px solid #4B4C4C;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-modal-close:after,
.product-modal-close:before {
  content: '';
  width: 14px;
  height: 2px;
  position: absolute;
  background: #4B4C4C;
}

.product-modal-close:after {
  transform: rotate(45deg);
}

.product-modal-close:before {
  transform: rotate(-45deg);
}

.product-modal .modal-line {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.product-modal .proportional {
  display: none;
  border: 3px solid #ffffff;
}

.product-modal .proportional.active {
  display: block;
}

.product-icon-line {
  display: flex;
  align-items: center;
  padding: 0 30px;
}

.view-360,
.video-icon {
  cursor: pointer;
  width: 40px;
  height: 40px;
  fill: #38A0F6;
  margin-right: 20px;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 10;
}

.overlay.active {
  display: block;
}

.product-3d {
  max-width: 1440px;
  margin: 0 auto;
  border: 3px solid #ffffff;
}

.product-3d img {
  max-width: 100%;
}

.product-3d-modal {
  display: none;
}

.product-3d-modal.active {
  display: block;
}

.cloudimage-360-prev,
.cloudimage-360-next {
  padding: 8px;
  background: transparent;
  border: none;
  display: none;
  position: absolute;
  z-index: 100;
  top: calc(50% - 15px);
}

.cloudimage-360-prev:focus,
.cloudimage-360-next:focus {
  outline: none;
}

.cloudimage-360-prev.not-active,
.cloudimage-360-next.not-active {
  opacity: 0.4;
  cursor: default;
}

.cloudimage-360-prev svg,
.cloudimage-360-next svg {
  fill: #A5AEBE;
}

.cloudimage-360-prev svg:hover,
.cloudimage-360-next svg:hover {
  fill: #4B4C4C;
}

.cloudimage-360-prev {
  left: 20px;
}

.cloudimage-360-next {
  right: 20px;
}

.cloudimage-inner-box {
  line-height: 0;
}

.cloudimage-inner-box img {
  bottom: 3%!important;
}

.cloudimage-inner-box canvas + div {
  top: 30px!important;
  right: 30px!important;
}

#ocfilter-mobile {
  display: none;
}

#ocfilter-mobile .navbar-toggle {
  display: none;
}

#ocfilter-mobile .navbar-header {
  float: none !important;
}

#ocfilter-mobile .navbar-default {
  background-color: #ffffff;
}

#ocfilter-mobile .panel {
  margin-bottom: 0;
}

#ocfilter.panel {
  border-radius: 0;
}

#ocfilter.ocfilter-hide {
  display: none;
}

#ocfilter.ocfilter-show {
  display: block;
}

#ocfilter .noUi-handle {
  box-shadow: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #171717;
  top: -15px;
}

#ocfilter .noUi-handle:before,
#ocfilter .noUi-handle:after {
  content: none;
}

#ocfilter .noUi-connect {
  background: #38A0F6;
}

#ocfilter .button-default:hover {
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.25);
}

#ocfilter .ocfilter-option .option-name {
  margin-bottom: 16px;
}

#ocfilter .option-values input[type="checkbox"] {
  width: 18px;
  height: 18px;
}

#ocfilter .option-values .noUi-horizontal {
  height: 5px;
}

#account-content .page-form-item {
  margin-bottom: 20px;
}

.page-main .buttons {
  text-align: right;
}

.page-main .button-default a {
  color: #ffffff;
}

#login-content .page-col {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(50% - 30px);
  display: flex;
}

#login-content .page-col .well {
  width: 100%;
}

#order-content {
  min-height: 44vh;
}

#order-content .fa.fa-eye {
  font-size: 1.375rem;
  color: #38A0F6;
  margin-right: 15%;
}

#order-content .order-table-button {
  vertical-align: middle;
}

#order-content .order-table-button .fa.fa-reply {
  font-size: 1.5rem;
  color: #FF423F;
}

#order-content .order-table-button .cart-fill-icon {
  width: 28px;
  height: 28px;
  fill: #38A0F6;
  margin-right: 10px;
}

#register-content .page-form-item {
  margin-bottom: 5px;
  width: 80%;
}

#return-content .fa.fa-eye {
  font-size: 1.375rem;
  color: #38A0F6;
  margin-right: 15%;
}

#information-content,
#account-content {
  min-height: 44vh;
  padding-bottom: 40px;
}

#information-content h1,
#account-content h1 {
  font-size: 2.5vw;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 40px;
}

.inf-img-block {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.inf-img-block .img-wrapper {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(16.666666666666668% - 30px);
  margin-bottom: 30px;
}

.contact-page {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.contact-page-block-4 {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(41.66666666666667% - 30px);
}

.contact-page-block-6 {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(58.333333333333336% - 30px);
}

.contact-page-block-6 .element {
  max-width: 100%;
}

.contact-page-address-items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 10px;
}

.contact-page-address-items .icon-item {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(16.666666666666668% - 30px);
  display: flex;
  justify-content: center;
  padding-top: 6px;
}

.contact-page-address-items .icon-item svg {
  width: 30px;
  height: 30px;
  fill: #4B4C4C;
}

.contact-page-address-items .icon-item .time-icon {
  margin-top: 34px;
}

.contact-page-address-items .text-item {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(83.33333333333334% - 30px);
}

.contact-page-address-items .text-item span,
.contact-page-address-items .text-item a,
.contact-page-address-items .text-item li {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 42px;
}

.contact-page-address-items .text-item a {
  color: #4B4C4C;
  margin-bottom: 16px;
}

.contact-page-address-items .text-item a:hover {
  color: #4B4C4C;
}

.contact-page-address-items .text-item ul {
  padding-left: 0;
}

.contact-page-address-items .text-item ul li {
  list-style: none;
  padding-left: 0;
}

.account-right-column .list-group-item {
  border-color: #223555;
  color: #223555;
}

.account-right-column .list-group-item:hover {
  border-color: #223555;
  color: #fff;
  background-color: #223555;
  text-shadow: none;
}

#blog-content h2 {
  text-align: center;
  color: #000;
  font-weight: 700;
  font-size: 3rem;
  text-transform: uppercase;
  margin-bottom: 34px;
}

#blog-content .pagination-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  flex-basis: 100%;
}

#blog-content .pagination-block {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(50% - 30px);
  text-align: right;
}

#blog-content .results {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(50% - 30px);
  line-height: 32px;
}

#content-blog .thumbnails {
  margin-top: 20px;
}

#content-blog .thumbnails .image-additional {
  float: none;
  max-width: 100%;
}

#content-blog .thumbnails .image-additional.img-wrapper {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(25% - 30px);
}

#content-blog .thumbnails .image-additional.img-wrapper img {
  max-width: 100%;
}

#content-blog .img-wrapper {
  margin-bottom: 20px;
}

#content-blog .img-wrapper-blog {
  display: flex;
  margin: 15px auto;
}

#content-blog .img-wrapper-blog img {
  max-width: 100%;
}

#content-blog .img-wrapper-blog.img-1 img {
  width: 100%;
}

#content-blog .img-wrapper-blog.img-2 img {
  width: 50%;
}

#content-blog .img-wrapper-blog.img-3 img {
  width: 33.333%;
}

#content-blog .img-wrapper-blog.img-4 img {
  width: 25%;
}

body.newsblog-category {
  background-color: #F5F5F5;
}

.newsblog-article li {
  font-size: 1rem;
  line-height: 20px;
}

article.page-row .img-wrapper {
  text-align: center;
}

.blog {
  margin-bottom: 80px;
}

.blog + footer {
  border-top: 2px solid #A5AEBE;
}

.blog h2 {
  text-align: center;
  font-weight: 700;
  font-size: 2.25rem;
  text-transform: uppercase;
  margin-bottom: 40px;
  margin-top: 40px;
  color: #000;
}

.blog-items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.blog-item {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(33.333333333333336% - 30px);
}

.blog-caption h3 {
  font-weight: 600;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 24px;
}

.blog-caption h3 a {
  color: #000;
}

.blog-caption p {
  font-weight: 500;
  color: #000;
  font-size: 0.875rem;
}

.blog-button {
  text-align: right;
}

.blog-button button {
  border: none;
  background-color: transparent;
  font-size: 0.875rem;
}

.blog-button button span {
  color: #FF423F;
}

.blog-button button span:hover {
  text-decoration: underline;
}

.blog-button button .fa {
  font-size: 1rem;
}

.blog-button-home {
  margin-top: 53px;
}

.blog-button-home .button-main {
  padding: 16px 23px;
  box-sizing: border-box;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 290px;
  border: 1px solid #38A0F6;
}

.blog-button-home .button-main:active,
.blog-button-home .button-main:focus {
  background-color: #fff;
  border: 1px solid #0A6CE2;
  box-shadow: 0 4px 8px rgba(10, 108, 226, 0.24);
  color: #0A6CE2;
}

.blog-button-home .button-main:active .blog-icon,
.blog-button-home .button-main:focus .blog-icon {
  fill: #0A6CE2;
}

.blog-button-home .blog-icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  fill: #fff;
}

.blog-category {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.blog-category-items {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(25% - 30px);
  padding-left: 0;
}

.blog-category-items li {
  list-style-type: none;
  margin-bottom: 40px;
  text-align: center;
  background-color: #ffffff;
}

.blog-category-items li a {
  display: block;
  font-size: 1.5rem;
  font-weight: 700;
  color: #A5AEBE;
  padding: 35px;
}

.blog-category-article-items {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(75% - 30px);
}

.blog-category-article-item {
  padding: 20px;
  background-color: #ffffff;
  margin-bottom: 35px;
}

.blog-category-article-item-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.blog-category-article-item-wrapper .img-wrapper {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(33.333333333333336% - 30px);
}

.blog-category-article-item-caption {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(66.66666666666667% - 30px);
}

.blog-category-article-item-caption h4 {
  font-size: 1.5rem;
  font-weight: 700;
}

.blog-category-article-item-caption h4 a {
  color: #000;
}

.blog-category-article-item-caption .read-more {
  float: right;
  color: #38A0F6;
  text-decoration: underline;
}

.blog-product {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(25% - 30px);
}

.blog-product .product-thumb {
  padding: 10px;
  border: none;
}

.blog-product .product-thumb .img-wrapper {
  text-align: center;
}

.blog-product .caption {
  min-height: 50px;
  text-align: center;
}

.blog-product .caption h4 {
  text-align: center;
}

.blog-product .caption h4 a {
  color: #000;
  font-weight: 500;
}

.blog-product-button .button-main {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.blog-product-button .button-disabled span {
  margin-right: 5px;
}

.blog-product-button svg {
  width: 28px;
  height: 28px;
  fill: #fff;
}

.blog-product-button span {
  font-size: 1.125rem;
  color: #ffffff;
  font-weight: 600;
  margin-left: 5px;
}

.blog-text-related {
  border-bottom: 1px solid #F5F5F5;
}

.blog-related-articles {
  border-bottom: 1px solid #F5F5F5;
}

.blog-related-articles-thumb {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.blog-related-articles-caption {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(66.66666666666667% - 30px);
}

.blog-related-articles .img-wrapper {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(33.333333333333336% - 30px);
}

.fancybox-skin {
  border-radius: 8px;
  background-color: #ffffff !important;
  box-shadow: rgba(0, 0, 0, 0.9) 0 0 2px, inset rgba(255, 255, 255, 0.4) 0 0 0;
  text-decoration: none;
  padding: 20px;
}

.request-popup .fancy_heading h3 {
  color: #171717;
  margin: 15px 0 0 0;
  font-weight: 700;
  text-align: center;
  font-size: 1.375rem;
}

.request-popup .buyonclick-box {
  display: flex;
  padding: 0 10px;
}

.request-popup .buyonclick-form {
  width: 59%;
}

.request-popup .product-buyonclick-title {
  padding: 10px;
}

.request-popup .product-buyonclick-img {
  width: 40%;
  margin-right: 10px;
}

.request-popup .product-buyonclick-img .img-wrapper {
  width: 120px;
}

.request-popup .page-form-item textarea {
  resize: vertical;
}

.success-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  background: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.9) 0 0 2px, inset rgba(255, 255, 255, 0.4) 0 0 0;
  text-decoration: none;
  vertical-align: middle;
  min-width: 300px;
  padding: 20px;
  width: 300px;
  min-height: 100px;
  margin-top: -100px;
  margin-left: -150px;
  text-align: center;
  font-size: 1.25em;
}

.success-popup p {
  color: #171717;
}

#pageform {
  width: 70%;
  margin: 0 auto;
}

#pageform .form-group {
  margin-left: 10px;
  margin-right: 10px;
}

#pageform textarea {
  resize: vertical;
}

#content-404 {
  padding-bottom: 40px;
}

#content-404 h1,
#content-404 p {
  text-align: center;
}

#content-404 .img-wrapper {
  text-align: center;
}

.iSearchBox {
  padding: 10px;
  background: white;
  display: none;
  box-shadow: 0 0 2px #A5AEBE;
  z-index: 10003;
  position: absolute;
  margin-top: 1px;
  overflow: inherit !important;
  left: 2%;
  text-align: left;
}

.iSearchBox li {
  list-style: none;
  margin: 0;
  cursor: pointer;
  padding: 0 10px;
  border-bottom: 1px solid #F5F5F5;
}

.iSearchBox li:last-child {
  margin-bottom: 0;
  font-size: 13px;
  padding: 8px 0;
}

.iSearchBox li.activekey {
  background-color: #F5F5F5;
  transition: 0.8s ease;
}

.iSearchBox li:hover {
  background-color: #F5F5F5;
  transition: 0.8s ease;
}

.iSearchBox li:hover h3,
.iSearchBox li:hover .iSearchModel,
.iSearchBox li:hover .iSearchPrice {
  color: #003264;
}

.iSearchBox li.iSearchNoResults,
.iSearchBox li.iSearchNoResults:hover {
  box-shadow: 0 1px 1px #fff;
  border-top: 1px solid #fff;
  cursor: default;
  text-align: center;
}

.iSearchBox li img {
  padding: 10px 10px 10px 0;
  display: inline-block;
}

.iSearchBox li .iSearchItem {
  min-height: 50px;
  display: inline-block;
}

.iSearchBox li .iSearchPrice {
  font-weight: bold;
  color: #333;
  font-size: 12px;
  display: block;
  padding: 10px 0;
}

.iSearchBox li .iSearchPrice span {
  font-size: 16px;
}

.iSearchBox li .iSearchPrice .specialPrice {
  color: red;
  text-decoration: line-through;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
}

.iSearchBox li .iSearchModel {
  color: #555;
  font-size: 11px;
  font-weight: normal;
}

.iSearchBox li h3 {
  padding: 0;
  margin: 0;
  padding-top: 10px;
  overflow: hidden;
  white-space: normal !important;
  line-height: 18px;
  font-size: 14px;
  font-weight: normal;
  color: #003264;
}

.iSearchBox ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

.iSearchBox ul li.iSearchHeading {
  margin: 0 0 10px 0;
  font-size: 18px;
  padding-left: 5px;
  position: relative;
}

.iSearchBox ul li.iSearchHeading:hover {
  border-color: white;
  cursor: default;
  box-shadow: none;
}

.iSearchBox .iSearchSpecial {
  display: inline-block;
  margin-left: 20px;
  font-size: 16px;
}

.iSearchBox .iSearchViewAllResults {
  color: #333;
  margin-bottom: 0;
  text-align: center;
  line-height: 20px;
}

.iSearchBoxWrapper {
  position: relative;
}

.iSearchBoxWrapper .clearfix {
  zoom: 1;
}

.iSearchBoxWrapper .clearfix:before {
  content: "";
  display: table;
}

.iSearchBoxWrapper .clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.iSearchBoxWrapper #iSearchBoxLoadingImage {
  background-image: url(../image/isearch/loading.gif);
  width: 16px;
  height: 16px;
  visibility: hidden;
  display: none !important;
}

/* Fixes the nivo slider overlap */

.slideshow .nivo-controlNav {
  z-index: 10;
}

.search-main {
  background-color: #F5F5F5;
  border-bottom: 4px solid #A5AEBE;
  padding-bottom: 40px;
}

.search-result .page-form-item {
  position: relative;
  margin-top: 20px;
}

.search-result .page-form-item #input-search {
  background-color: #F5F5F5;
}

.search-result .page-form-item .form-control {
  text-align: left;
  width: 70%;
  display: inline-block;
  margin-right: 3%;
}

.search-result .page-form-item .form-control-label {
  position: absolute;
  top: -10px;
  background-color: transparent;
  z-index: 1;
}

.search-result .page-form-item .form-control-label:before {
  content: '';
  position: absolute;
  display: block;
  height: 3px;
  background-color: #F5F5F5;
  width: 100%;
  top: 10px;
  left: 0;
  z-index: -1;
}

.search-result .page-form-item .button-default {
  display: inline-block;
  border: 2px solid #223555;
}

.search-products {
  margin-top: 40px;
}

.search-product {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(25% - 30px);
  background-color: #fff;
  margin-bottom: 30px;
  padding: 20px;
}

.search-product .price {
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;
}

.search-product .price-old {
  font-size: 1.125rem;
  font-weight: 400;
  text-decoration: line-through;
  color: #F8082D;
}

.search-product .button-group {
  text-align: center;
}

.search-product .button-group .button-cat {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.search-product .button-group .button-cat .cart-icon {
  width: 22px;
  height: 22px;
  fill: #fff;
  margin-right: 10px;
}

.search-product .button-group .button-cat span {
  color: #ffffff;
  font-size: 1.125rem;
}

body.oHidden {
  overflow: hidden;
}

.lPage {
  /* Landing color */
  overflow: hidden;
}

.lPage-relative {
  position: relative;
}

.lPage-relative:after {
  content: '';
  display: block;
  padding-top: 49.23%;
  width: 100%;
}

.lPage-relative .lPage-text {
  flex: 0 0 50%;
}

.lPage-banner {
  position: relative;
  background-image: linear-gradient(5deg, #2C2D2D 30%, #000 30.3% 100%);
  background-position: top right;
  display: flex;
  padding: 40px;
}

.lPage-banner .img-wrapper {
  margin-left: 10vw;
}

.lPage-h1 {
  font-family: 'Oswald', sans-serif;
  font-size: 7.56699947vw;
  color: #0A6CE2;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 15vw;
  flex-grow: 1;
  text-align: center;
  flex-basis: 600px;
}

.lPage-section {
  padding: 50px 35px 60px;
  background-color: #000;
  display: flex;
}

.lPage-section .img-wrapper {
  flex: 1 0 50%;
  text-align: center;
}

.lPage-section.size {
  flex-wrap: wrap;
}

.lPage-section.size h2 {
  flex-basis: 100%;
  text-align: center;
}

.lPage-section.video {
  display: block;
}

.lPage-section.video h2 {
  text-align: center;
}

.lPage-section.where-by {
  display: block;
}

.lPage-section.where-by h2 {
  text-align: center;
  color: #EFE71B;
  font-weight: bold;
  font-size: 2.75rem;
  text-transform: uppercase;
  margin-bottom: 44px;
}

.lPage-section.where-by p {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
  line-height: 1.5;
  max-width: 1200px;
  margin: 0 auto;
}

.lPage-section.where-by p span {
  color: #EFE71B;
}

.lPage-bg {
  background-image: linear-gradient(-5deg, #2C2D2D 32%, #000 32.3% 100%);
  background-position: top left;
}

.lPage h2 {
  color: #EFE71B;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 12px;
  margin-bottom: 40px;
}

.lPage-text {
  padding-left: 8.07291667vw;
  padding-top: 4.6875vw;
  flex: 1 0 50%;
}

.lPage-text p {
  color: #EFE71B;
  font-size: 1.125rem;
  font-weight: bold;
  text-transform: uppercase;
}

.lPage-text p.text {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  text-transform: unset;
  line-height: 30px;
}

.lPage-product-color .color-product {
  margin-top: 40px;
  display: inline-block;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  cursor: pointer;
}

.lPage-product-image {
  position: absolute;
  right: 10%;
  bottom: 0;
}

.lPage-product-image .img-wrapper {
  position: relative;
  width: 59.37992643vw;
}

.lPage-product-image .img-wrapper:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: linear-gradient(to top, #000, transparent);
}

.lPage-product-advantages {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.lPage-product-advantages .check-icon {
  fill: #EFE71B;
  width: 30px;
  height: 30px;
  margin-bottom: 20px;
}

.lPage-product-advantages span {
  display: block;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
}

.lPage-item {
  text-align: center;
  padding-top: 40px;
}

.lPage-item:not(:last-child) {
  margin-right: 8px;
}

.lPage-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 11;
  background-color: #000;
  padding-top: 30px;
  padding-left: 7.29166667vw;
  padding-right: 7.29166667vw;
}

.lPage-modal.active {
  display: block;
}

.lPage-modal.active .mCSB_container {
  display: flex;
}

.lPage-modal-btn {
  position: relative;
}

.lPage-modal-link {
  display: flex;
  width: 50px;
  height: 50px;
  border: 1px solid #EFE71B;
  border-radius: 50%;
  justify-content: center;
  flex-flow: row wrap;
  align-content: center;
  position: absolute;
  cursor: pointer;
}

.lPage-modal-link:hover .lPage-modal-link-elem {
  width: 30px;
  height: 30px;
  border-radius: 3px;
  transition: 0.6s ease;
}

.lPage-modal-link:hover .lPage-modal-link-elem .square-icon {
  opacity: 1;
}

.lPage-modal-link-elem {
  width: 10px;
  height: 10px;
  background-color: #EFE71B;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.8s ease;
}

.lPage-modal-link-elem .square-icon {
  fill: #fff;
  width: 60%;
  height: 60%;
  opacity: 0;
}

.lPage-modal-link:nth-child(2) {
  top: 0;
  left: 0;
  right: 360px;
  bottom: 145px;
  margin: auto;
}

.lPage-modal-link:nth-child(3) {
  top: auto;
  left: 0;
  right: 10px;
  bottom: 80px;
  margin: auto;
}

.lPage-modal-link:nth-child(4) {
  top: auto;
  left: 30px;
  right: 0;
  bottom: 230px;
  margin: auto;
}

.lPage-modal-link:nth-child(5) {
  top: 0;
  left: 0;
  right: 0;
  bottom: 40px;
  margin: auto;
}

.lPage-modal-link:nth-child(6) {
  top: 150px;
  left: 230px;
  right: 0;
  bottom: auto;
  margin: auto;
}

.lPage-modal-close {
  margin: 0 auto;
  width: 50px;
  height: 50px;
  background-color: #EFE71B;
  position: relative;
  border-radius: 50%;
  cursor: pointer;
  flex: 0 0 auto;
  z-index: 2;
}

.lPage-modal-close:after,
.lPage-modal-close:before {
  content: '';
  display: block;
  width: 3px;
  height: 24px;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.lPage-modal-close:after {
  transform: rotate(45deg);
}

.lPage-modal-close:before {
  transform: rotate(-45deg);
}

.lPage-modal-content {
  margin-top: 20px;
  max-width: 100vw;
  height: calc(100vh - 110px);
}

.lPage-modal-content .img-wrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.lPage-modal-content .lPage-text {
  flex: 0 1 40%;
  padding-left: 16px;
}

.lPage-video {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(50% - 30px);
}

.lPage-button .button-where-by {
  display: block;
  max-width: 344px;
  color: #ffffff;
  background-color: #EFE71B;
  text-align: center;
  padding: 30px 30px;
  font-weight: bold;
  margin: 60px auto 10px;
}

.section3d {
  background-color: #000000;
}

.warning {
  font-weight: bold;
}

.screen-size {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.content-wrapper {
  max-width: 1200px;
  position: relative;
}

.content-wrapper:after {
  content: "";
  padding-top: 50%;
  display: block;
}

#X-2608 .lPage-section:nth-child(2) {
  background-image: url('/image/catalog/lending-image/x2608/x2608-15.png');
  background-repeat: no-repeat;
  background-position: top right;
}

#X-2608 .lPage-product-color .color-product:first-child {
  background: linear-gradient(90deg, #fff 50%, orange 50%);
}

#X-2608 .lPage-product-color .color-product:nth-child(2) {
  background: linear-gradient(90deg, #fff 50%, red 50%);
}

#X-2608 .lPage-product-color .color-product:last-child {
  background: linear-gradient(90deg, #fff 50%, blue 50%);
}

#X-2608 .lPage-product-color .color-product:not(:last-child) {
  margin-right: 40px;
}

footer {
  background-color: #F5F5F5;
  padding-top: 50px;
  padding-bottom: 35px;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.footer-item {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(50% - 30px);
}

.footer-item h5 {
  font-size: 1rem;
}

.footer-item li {
  font-size: 1rem;
  font-weight: 600;
  line-height: 27px;
}

.footer-item li a {
  color: #000;
}

.footer-item li a:hover {
  color: #38A0F6;
}

.footer-item-first {
  text-align: right;
}

.list-social {
  padding-left: 0;
  list-style: none;
}

.list-social-item {
  display: inline-block;
  margin-right: 16px;
}

.list-social-item:first-child a:hover {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}

.list-social-item:nth-child(2) a:hover {
  background-color: #ff0000;
}

.list-social-item:nth-child(3) a:hover {
  background-color: #3b5998;
}

.list-social-item:last-child a:hover {
  background-color: #E60023;
}

.list-social-item a {
  width: 30px;
  height: 30px;
  background-color: #c4c4c4;
  border-radius: 50%;
  text-align: center;
  display: block;
  position: relative;
}

.list-social-item a:hover {
  animation: animate ease 0.6s forwards;
}

.list-social-item a:hover svg {
  fill: #fff;
}

.list-social-item svg {
  width: 16px;
  height: 16px;
  fill: #4B4C4C;
  vertical-align: middle;
  transition: all ease 0.2s;
}

@keyframes animate {
  0%, 20% {
    transform: scale(1);
    border-radius: 40px;
    background-color: #9E9E9E;
  }

  30% {
    transform: scale(1.2);
  }

  40% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1);
    border-radius: 10px;
  }

  60%, 100% {
    transform: scale(1);
    border-radius: 5px;
  }
}

.copyright {
  text-align: center;
  padding-top: 25px;
  color: #A5AEBE;
  margin-bottom: 0;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}

.scroll-top {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 40px;
  height: 40px;
  border: 1px solid;
  color: #223555;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 2px 6px rgba(40, 57, 99, 0.2);
  cursor: pointer;
  display: none;
}

.scroll-top:hover {
  color: #38A0F6;
  box-shadow: 0 2px 6px rgba(19, 90, 206, 0.2);
}

.scroll-top:hover:after {
  color: #38A0F6;
}

.scroll-top:after {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  color: #223555;
  border-top: 3px solid;
  border-right: 3px solid;
  position: absolute;
  top: 14px;
  left: 0;
  right: 0;
  margin: auto;
  transform: rotate(-45deg);
}

html {
  font-size: 16px;
}

body {
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
  max-width: 1920px;
  margin: 0 auto;
}

body p {
  font-size: 1rem;
}

body.modal-open {
  overflow: hidden;
}

.wrapper {
  max-width: 1650px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
}

.wrapper-full {
  padding-left: 30px;
  padding-right: 30px;
}

.d-flex {
  display: flex;
}

.img-wrapper img {
  max-width: 100%;
  line-height: 0;
}

/******** BUTTON ********/

.button-main {
  padding: 11px 30px;
  font-size: 1.5rem;
  text-transform: uppercase;
  border-radius: 50px;
  border: none;
  background-color: #38A0F6;
}

.button-disabled {
  background-color: #A5AEBE;
  color: #4B4C4C;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-disabled span {
  margin-right: 30px;
}

.button-disabled .disabled-icon {
  fill: #4B4C4C;
  width: 36px;
  height: 36px;
}

.button-default {
  font-size: 0.875rem;
  background-color: #223555;
  color: #ffffff;
  text-transform: none;
  padding: 8px 15px;
}

.button-default-grey {
  background-color: #A5AEBE;
}

.button-border {
  box-sizing: border-box;
  font-size: 0.875rem;
  background-color: #ffffff;
  color: #223555;
  text-transform: none;
  padding: 8px 32px;
  border: 1px solid #223555;
  transition: 0.5s ease;
}

.button-border a {
  color: #223555;
}

/******** PAGE ********/

.page-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.page-row .left-3,
.page-row .right-3 {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(25% - 30px);
}

.page-6 {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(50% - 30px);
}

.page-9 {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(75% - 30px);
}

.page-12 {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(100% - 30px);
}

.page-form-item.data .form-control-label {
  z-index: 3;
}

.page-form-item.required .form-control-label:after {
  content: '* ';
  color: #F00;
  font-weight: bold;
}

.page-form-item input.form-control,
.page-form-item select.form-control,
.page-form-item textarea.form-control {
  border: 2px solid #A5AEBE;
  box-sizing: border-box;
  border-radius: 30px;
  font-size: 1rem;
  text-align: center;
  min-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.page-form-item input.form-control:focus,
.page-form-item select.form-control:focus,
.page-form-item textarea.form-control:focus {
  border-color: #38A0F6;
  box-shadow: none;
}

.page-form-item .form-control-label {
  position: relative;
  left: 30px;
  top: 15px;
  background-color: #ffffff;
  padding: 0 10px;
  font-size: 0.875rem;
  color: #223555;
}

.page-form-item .text-danger ~ input.form-control {
  border-color: #F8082D;
}

/******** HOME ********/

.main-content {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.main-content .left-3,
.main-content .right-3 {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(25% - 30px);
}

.home-6 {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(50% - 30px);
}

.home-9 {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(75% - 30px);
}

.home-12 {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(100% - 30px);
}

/******** PRODUCT ********/

.product-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.product-row .left-3,
.product-row .right-3 {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(25% - 30px);
}

.product-6 {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(50% - 30px);
}

.product-9 {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(75% - 30px);
}

.product-12 {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(100% - 30px);
}

/******** CATEGORY ********/

.category-row,
.category-product-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.category-row .left-3,
.category-product-row .left-3,
.category-row .right-3,
.category-product-row .right-3 {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(25% - 30px);
}

.category-6 {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(50% - 30px);
}

.category-9 {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(75% - 30px);
}

.category-12 {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(100% - 30px);
}

/******** iSEARCH ********/

.search-row,
.search-products {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.search-3 {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(33.333333333333336% - 30px);
}

/******** PROPORTIONAL ELEMENT ********/

.element {
  max-width: 820px;
  margin: 0 auto;
}

.proportional {
  position: relative;
}

.proportional iframe {
  width: 100%;
  border: none;
}

.proportional_content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.proportional-wrapper {
  padding: 40px;
  background-color: #F5F5F5;
  margin-bottom: 20px;
}

.after-class:after {
  content: '';
  display: block;
  padding-top: 56.25%;
  height: 0;
  width: 100%;
}

.clearfix::after {
  display: block;
  content: "";
  clear: both;
}

@media screen and (min-width: 1024px) {
  .header-sub-menu-item:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    transition: 0.3s;
  }

  .product-overview-menu-button:hover svg,
  .product-overview-menu-button:active svg {
    fill: #38A0F6;
  }

  .product-overview-menu-button:hover span,
  .product-overview-menu-button:active span {
    color: #38A0F6;
  }

  .lp-button .button-main:hover {
    background-color: #ffb2b0;
    transition: 0.05s ease;
  }

  .lp-button .button-main:hover a {
    color: #FF423F;
  }

  .lp-button .button-main:active {
    background-color: #ffffff;
    box-shadow: 0 8px 16px rgba(246, 72, 90, 0.24);
    outline: none;
  }

  .lp-button .button-main:active a {
    color: #f6485a;
  }

  .lp-fix-menu-button:hover svg {
    fill: #38A0F6;
  }

  .lp-fix-menu-button:hover span {
    color: #38A0F6;
  }

  .lp-fix-menu-button.x-close:hover {
    transform: rotate(-180deg);
    transition: 0.6s;
  }

  .lp-fix-menu-button.x-close:hover:before,
  .lp-fix-menu-button.x-close:hover:after {
    background-color: #38A0F6;
  }

  .breadcrumb li a:hover {
    color: #171717;
  }

  #category-content .button-default:hover a {
    color: #000;
  }

  .category-product-item:hover .category-button {
    display: block;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  }

  .category-product-item:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  }

  .category-button .button-cat:hover {
    background: #ffffff;
    color: #000;
  }

  .category-button .button-cat:hover a {
    color: #000;
  }

  .category-button .button-cat:hover .disabled-icon {
    fill: #000;
  }

  .product-image-additional-item .img-wrapper:hover {
    border: 2px solid #38A0F6;
  }

  .product-buttons-buy .button-buy:hover {
    background-color: #135ACE;
  }

  .product-buttons-buy .button-onclick:hover {
    background-color: #38A0F6;
  }

  #order-content .fa.fa-eye:hover {
    color: #223555;
  }

  #order-content .order-table-button .fa.fa-reply:hover {
    color: #F8082D;
  }

  #order-content .order-table-button .cart-fill-icon:hover {
    fill: #223555;
  }

  #return-content .fa.fa-eye:hover {
    color: #223555;
  }

  .blog-button-home .button-main:hover {
    background-color: #E5EFFF;
    border: 1px solid #0A6CE2;
    box-shadow: 0 8px 16px rgba(10, 108, 226, 0.2);
    color: #0A6CE2;
  }

  .blog-button-home .button-main:hover .blog-icon {
    fill: #0A6CE2;
  }

  .blog-category-items li:hover,
  .blog-category-items li:active {
    background-color: #38A0F6;
  }

  .blog-category-items li:hover a,
  .blog-category-items li:active a {
    color: #ffffff;
  }

  .blog-product .product-thumb:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  }

  .blog-product-button .button-main:hover {
    background-color: #096dbf;
  }

  .search-product:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
    transition: 0.6s ease;
  }

  .search-product .button-group .button-cat:hover {
    background-color: #223555;
  }

  .button-default:hover {
    background-color: #1b2a43;
  }

  .button-default-grey:hover {
    background-color: #6a7993;
  }

  .button-border:hover {
    border-color: #38A0F6;
    background-color: #38A0F6;
    transition: 0.5s ease;
  }

  .button-border:hover a {
    color: #ffffff;
  }
}

@media screen and (min-width: 1200px) {
  .header-sub-menu-item .img-wrapper {
    width: 140px;
    height: 140px;
  }
}

@media screen and (max-width: 1650px) {
  .lp-fourth-screen,
  .lp-fifth-screen,
  .lp-sixth-screen {
    min-height: 1px;
  }

  .lp-h2 {
    margin-bottom: 3.03030303vw;
    font-size: 2.42424242vw;
  }

  .lp-text {
    font-size: 1.45454545vw;
    line-height: 1.4;
  }

  .lp-text-block {
    margin-top: 60px;
  }

  .lp-icon-items {
    margin-top: 40px;
  }

  .lp-icon-items.vertical .lp-icon-item {
    margin-top: 40px;
  }

  .lp-icon-items.vertical .lp-icon-item .check-icon {
    margin-bottom: 20px;
  }

  .product-group {
    width: 80%;
  }
}

@media screen and (max-width: 1500px) {
  .lp-text-block {
    margin-top: 40px;
  }

  .lp-icon-items.horizontal .lp-icon-item span {
    font-size: 1rem;
  }

  .lp-icon-items.vertical .lp-icon-item .check-icon {
    margin-bottom: 10px;
  }

  .lp-icon-items.vertical .lp-icon-item span {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1440px) {
  .home-product-list li {
    line-height: normal;
    margin-bottom: 8px;
  }

  .home-product-button {
    margin-top: 20px;
  }

  .lPage-relative .lPage-text {
    flex: 0 0 70%;
  }

  .lPage-product-image {
    right: 35px;
  }
}

@media screen and (max-width: 1400px) {
  .lp-third-screen {
    min-height: 600px;
  }

  .lp-h1 {
    font-size: 5.125rem;
  }

  .lp-icon-item span {
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 1366px) {
  .lp-icon-items.vertical {
    display: flex;
    flex-wrap: wrap;
  }

  .lp-icon-items.vertical .lp-icon-item {
    width: 50%;
  }

  .product-group {
    width: 95%;
  }
}

@media screen and (max-width: 1280px) {
  .home-gtr {
    margin-left: -20px;
    margin-right: -20px;
  }

  .home-product-list li {
    font-size: 0.875rem;
    margin-bottom: 5px;
  }

  .category-button-product .button-cat {
    width: 100%;
  }

  .category-button-product .button-disabled {
    width: 100%;
  }

  .lPage-relative:after {
    padding-top: 54.5%;
  }

  .lPage-relative .lPage-text {
    flex: 0 0 74%;
  }

  .lPage-section {
    padding: 30px 35px 40px;
  }

  .lPage-section .img-wrapper {
    flex: 1 0 40%;
    padding-left: 15px;
  }

  .lPage-text {
    flex: 1 0 60%;
  }

  .lPage-modal {
    padding: 30px 65px 0;
  }

  .wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .wrapper-full {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .header-menu {
    justify-content: space-evenly;
  }

  .header-menu a,
  .header-menu span {
    font-size: 0.875rem;
  }

  .header-menu-item,
  .header-menu-info {
    margin-right: 0;
  }

  .header-sub-menu-item.all-category span {
    font-size: 1rem;
  }

  .lang-button span {
    font-size: 0.875rem;
  }

  #details .details-icons-items svg {
    width: 180px;
    height: 180px;
  }

  .lPage-modal-link:nth-child(4) {
    bottom: 195px;
  }
}

@media screen and (max-width: 1080px) {
  .lPage-relative:after {
    padding-top: 60%;
  }
}

@media screen and (max-width: 1024px) {
  .logo-block {
    order: 1;
  }

  .burger {
    display: flex;
  }

  #menu {
    width: auto;
    padding: 0;
  }

  .header-menu {
    display: none;
  }

  .header-menu span {
    display: none;
  }

  .header-menu-first-category {
    display: inline-block;
  }

  .header-shopping-cart {
    order: 2;
  }

  .header-lang {
    order: 3;
  }

  .category-module {
    margin-bottom: 40px;
  }

  .home-product-item {
    min-height: 450px;
  }

  .home-product-list li {
    font-size: 1rem;
  }

  .home-product-button {
    margin-top: 16px;
  }

  .product-overview-menu-items {
    text-align: center;
  }

  .product-overview-menu-button {
    margin-right: 40px;
  }

  .lp-first-screen {
    height: 480px;
    background-size: cover;
  }

  .lp-first-screen .lp-h1 {
    margin-top: 80px;
    font-size: 3rem;
    text-align: center;
  }

  .lp-seventh-screen,
  .lp-eighth-screen,
  .lp-ninth-screen,
  .lp-tenth-screen,
  .lp-eleventh-screen {
    min-height: 250px;
  }

  .lp-h2 {
    font-size: 1.25rem;
  }

  .lp-text {
    font-size: 1rem;
    line-height: normal;
  }

  .lp-text-block {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-right: 20px;
  }

  .lp-icon-items.horizontal .lp-icon-item {
    padding: 0 10px;
  }

  .lp-icon-item .check-icon {
    margin-bottom: 10px;
  }

  .lp-icon-item span {
    line-height: normal;
  }

  .lp-where-buy {
    min-height: 450px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .lp-where-buy h1 {
    font-size: 3rem;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .lp-where-buy p {
    padding: 0 20px;
    font-size: 1.125rem;
    line-height: normal;
  }

  .lp-button .button-main {
    padding: 16px 40px;
  }

  .lp-button .button-main a {
    font-size: 1.125rem;
  }

  #details .details-icons-items svg {
    width: 140px;
    height: 140px;
  }

  #details .details-icons-items .details-item {
    width: calc(100% / 2 - 20px);
  }

  #category-content .button-default:active a,
  #category-content .button-default:focus {
    color: #ffffff;
  }

  #category-content .button-default a {
    color: #171717;
  }

  .category-sort {
    width: calc(33.333333333333336% - 30px);
  }

  .category-per-page {
    width: calc(33.333333333333336% - 30px);
  }

  .category-product-item {
    width: calc(33.333333333333336% - 30px);
  }

  .category-product-thumb {
    padding: 20px 10px;
  }

  .category-product-row .category-product-item {
    width: calc(50% - 30px);
  }

  .category-product-link {
    display: none;
  }

  .category-button {
    position: relative;
    height: auto;
    background: none;
    padding-top: 0;
    display: block;
  }

  .category-button-overlay {
    position: unset;
  }

  .category-button .button-cat {
    border-color: #171717;
    margin-bottom: 10px;
    font-size: 1.7578125vw;
  }

  .category-button .button-cat:active a,
  .category-button .button-cat:focus {
    color: #ffffff;
  }

  .category-button .button-cat a {
    color: #171717;
  }

  .category-button .button-cat span {
    color: #171717;
  }

  .category-button .button-cat span:active,
  .category-button .button-cat span:focus {
    color: #ffffff;
  }

  .category-button .button-cat .disabled-icon {
    fill: #171717;
  }

  .category-button .button-disabled {
    background-color: #A5AEBE;
  }

  .category-button .button-disabled:active,
  .category-button .button-disabled:focus {
    background-color: #A5AEBE;
    color: #171717;
  }

  .category-button .button-disabled:active span,
  .category-button .button-disabled:focus span {
    color: #171717;
  }

  .category-button .button-disabled span {
    color: #171717;
  }

  .category-button-product .button-cat {
    font-size: 1.953125vw;
  }

  .category-button-product .button-disabled {
    font-size: 1.953125vw;
  }

  #tab-specification .table {
    width: 100%;
  }

  .product-name h1 {
    font-size: 1.5rem;
  }

  .product-advantages-item {
    width: calc(33.333333333333336% - 30px);
  }

  #tab-description p {
    line-height: 25px;
  }

  .description-text {
    width: calc(100% - 30px);
  }

  .description-image {
    display: none;
  }

  .description-advantages-block h2 {
    font-size: 1.5rem;
  }

  .description-advantages-image {
    width: calc(50% - 30px);
  }

  .description-advantages-image:nth-child(2) {
    display: none;
  }

  .description-advantages-text {
    width: calc(50% - 30px);
  }

  .description-parameters li {
    line-height: 30px;
  }

  .description-parameters li:after {
    top: 10px;
  }

  #ocfilter-mobile {
    display: block;
  }

  #ocfilter-mobile .navbar-toggle {
    display: block !important;
  }

  #ocfilter .panel-heading {
    display: none;
  }

  .inf-img-block .img-wrapper {
    width: calc(25% - 30px);
  }

  .blog-category-items {
    width: calc(100% - 30px);
  }

  .blog-category-items {
    display: flex;
    justify-content: space-between;
  }

  .blog-category-items li a {
    padding: 32px 8%;
  }

  .blog-category-article-items {
    width: calc(100% - 30px);
  }

  .blog-product {
    width: calc(33.333333333333336% - 30px);
  }

  .lPage-text {
    padding-left: 0;
  }

  .lPage-product-color .color-product {
    margin-top: 20px;
    width: 38px;
    height: 38px;
  }

  .lPage-modal {
    padding: 20px 10px 20px 30px;
  }

  .lPage-modal-link:nth-child(2) {
    right: 290px;
    bottom: 130px;
  }

  .lPage-modal-link:nth-child(3) {
    right: 0;
    bottom: 60px;
  }

  .lPage-modal-link:nth-child(4) {
    left: 40px;
    bottom: 180px;
  }

  .lPage-modal-link:nth-child(6) {
    top: 120px;
    left: 190px;
  }

  .lPage-modal-content .img-wrapper {
    flex: 0 1 50%;
  }

  .lPage-modal-content .lPage-text {
    flex: 0 1 50%;
  }

  .wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .wrapper-full {
    padding-left: 20px;
    padding-right: 20px;
  }

  .category-row .left-3 {
    width: calc(100% - 30px);
  }

  .category-6 {
    width: calc(100% - 30px);
  }

  .category-9 {
    width: calc(100% - 30px);
  }
}

@media screen and (max-width: 992px) {
  .pre-header #search-input {
    width: 620px;
  }

  .category-module-item a {
    font-size: 1.125rem;
  }

  .home-product-item {
    width: calc(50% - 30px);
  }

  .lp-icon-items.horizontal .lp-icon-item span {
    font-size: 0.875rem;
  }

  .product-buttons-buy .button-main {
    font-size: 1.25rem;
  }

  .product-buttons-buy .button-buy {
    margin-right: 24px;
  }

  .inf-img-block .img-wrapper {
    width: calc(33.333333333333336% - 30px);
  }

  .blog-items {
    justify-content: center;
  }

  .blog-item {
    width: calc(100% - 30px);
  }

  #pageform {
    width: 100%;
  }

  .search-product {
    width: calc(50% - 30px);
  }

  .lPage-relative:after {
    padding-top: 65%;
  }

  .lPage-section.video .page-row {
    justify-content: center;
  }

  .lPage-modal-link {
    width: 40px;
    height: 40px;
  }

  .lPage-modal-link:hover .lPage-modal-link-elem {
    width: 24px;
    height: 24px;
  }

  .lPage-video {
    width: calc(83.33333333333334% - 30px);
  }

  .lPage-video {
    margin-bottom: 30px;
  }

  .wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .wrapper-full {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 940px) {
  .lp-second-screen {
    display: block;
  }

  .lp-second-screen .lp-image {
    padding-top: 60px;
    width: 80%;
    margin: 0 auto;
  }

  .lp-third-screen .lp-text-block {
    background-color: transparent;
    padding-top: 15%;
  }

  .lp-fifth-screen,
  .lp-sixth-screen,
  .lp-fourth-screen {
    display: block;
  }

  .lp-fifth-screen {
    display: flex;
    flex-direction: column;
  }

  .lp-fifth-screen .lp-text-block {
    order: 1;
  }

  .lp-seventh-screen,
  .lp-eighth-screen,
  .lp-ninth-screen,
  .lp-tenth-screen,
  .lp-eleventh-screen {
    display: block;
    text-align: center;
  }

  .lp-eighth-screen,
  .lp-tenth-screen {
    display: flex;
    flex-direction: column;
  }

  .lp-eighth-screen .lp-image,
  .lp-tenth-screen .lp-image {
    order: 1;
  }

  .lp-image {
    width: 100%;
  }

  .lp-text-block {
    padding-bottom: 20px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    background-color: #000000;
  }

  .lp-icon-items.horizontal {
    margin: 30px auto 0 auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 900px) {
  .lPage-modal-link:nth-child(2) {
    right: 255px;
    bottom: 120px;
  }

  .lPage-modal-link:nth-child(6) {
    top: 105px;
    left: 170px;
  }
}

@media screen and (max-width: 860px) {
  .lPage h2 {
    margin-bottom: 20px;
  }

  .lPage-text p.text {
    line-height: 1.5;
  }
}

@media screen and (max-width: 768px) {
  .pre-header > a {
    margin-right: 5%;
  }

  .pre-header .account-text {
    display: none;
  }

  .pre-header .dropdown-menu-left {
    right: 0;
    left: auto;
    z-index: 4;
  }

  .pre-header #search-input {
    width: 480px;
  }

  .home-gtr {
    margin-bottom: 40px;
  }

  .category-module-item {
    width: calc(100% - 30px);
  }

  .category-module-item {
    margin-bottom: 20px;
    display: flex;
  }

  .category-module-item a {
    font-size: 1.375rem;
  }

  .category-module-item .img-wrapper {
    display: none;
  }

  .home-product-items {
    justify-content: center;
  }

  .home-product-item {
    width: calc(100% - 30px);
  }

  .lp-icon-item .check-icon {
    width: 32px;
    height: 32px;
  }

  #details #p_name {
    font-size: 1.375rem;
  }

  #details .details-wrapper {
    justify-content: center;
  }

  #details .details-wrapper .img-wrapper {
    width: calc(50% - 30px);
  }

  #details .details-block {
    width: calc(100% - 30px);
  }

  #details .details-icons-items .details-item {
    width: calc(100% / 3 - 20px);
  }

  #details .details-line {
    font-size: 1.25rem;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  #details .details-name {
    width: calc(100% - 30px);
  }

  #details .details-name {
    font-size: 1.25rem;
    margin-bottom: 20px;
    font-weight: 700;
  }

  #details .details-value {
    width: calc(100% - 30px);
  }

  .category-product-item {
    width: calc(50% - 30px);
  }

  .category-button .button-cat {
    font-size: 1rem;
  }

  .category-button-product .button-cat {
    font-size: 1rem;
  }

  .category-button-product .button-disabled {
    font-size: 1rem;
  }

  #product-options .product-group {
    justify-content: center;
    flex-wrap: wrap;
  }

  #product.product-group {
    display: block;
  }

  #tab-review {
    width: 100%;
  }

  .product-price {
    text-align: center;
  }

  .product-buttons-buy {
    justify-content: center;
  }

  .product-stock .stock-value {
    font-size: 1.5rem;
    margin-left: 40px;
  }

  .product-stock .stock-value:before {
    top: 5px;
  }

  .product-tab-wrapper .nav.nav-tabs li {
    width: auto;
    margin-right: 10px;
  }

  .product-tab-wrapper .nav.nav-tabs li a {
    font-size: 1.125rem;
  }

  .product-advantages-item {
    width: calc(50% - 30px);
  }

  .description h3 {
    text-indent: 0;
  }

  .description-advantages-wrap h3 {
    text-indent: 0;
  }

  .description-advantages-image {
    width: calc(100% - 30px);
  }

  .description-advantages-image {
    text-align: center;
  }

  .description-advantages-image.image-3 {
    width: calc(100% - 30px);
  }

  .description-advantages-text {
    width: calc(100% - 30px);
  }

  .description-advantages-text.long-text {
    width: calc(100% - 30px);
  }

  .description-advantages-img-text {
    width: calc(100% - 30px);
  }

  .description-advantages-img-text .img-wrapper {
    float: left;
    margin-right: 10px;
    width: 200px;
    margin-bottom: 0;
  }

  .description-parameters {
    padding: 20px 0;
  }

  .description-parameters-wrap {
    padding: 20px;
    width: 100%;
  }

  .description-parameters h3 {
    font-size: 1.25rem;
    text-indent: 0;
  }

  .description-parameters li {
    font-size: 1rem;
    line-height: 1.4;
    text-indent: 0;
  }

  .description-parameters li:after {
    top: 5px;
    left: 5px;
  }

  .product-image-additional {
    display: none;
  }

  .product-image-main {
    display: none;
  }

  .product-image-mobile.owl-carousel {
    display: block;
  }

  #login-content .page-col {
    width: calc(100% - 30px);
  }

  .blog h2 {
    font-size: 1.75rem;
  }

  .blog-caption h3 {
    font-size: 1.25rem;
  }

  .blog-caption p {
    font-size: 0.75rem;
  }

  .blog-category-items li a {
    padding: 32px 35px;
  }

  .blog-product {
    width: calc(50% - 30px);
  }

  .lPage-relative:after {
    display: none;
  }

  .lPage-section {
    display: block;
  }

  .lPage-section .img-wrapper {
    width: 80%;
    margin: 0 auto;
  }

  .lPage-section .img-wrapper.lPage-modal-btn {
    margin: 20px auto 0;
  }

  .lPage-bg {
    background-image: linear-gradient(-5deg, #2C2D2D 25%, #000 25.3% 100%);
  }

  .lPage-text p.text {
    font-size: 0.875rem;
  }

  .lPage-product-color {
    text-align: center;
  }

  .lPage-product-color .color-product {
    text-align: center;
    margin-bottom: 20px;
  }

  .lPage-product-image {
    position: relative;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .lPage-modal.active .mCSB_container {
    display: block;
  }

  .lPage-modal-link:nth-child(2) {
    right: 350px;
    bottom: 150px;
  }

  .lPage-modal-link:nth-child(4) {
    left: 25px;
    bottom: 230px;
  }

  .lPage-modal-link:nth-child(6) {
    top: 145px;
    left: 210px;
  }

  .lPage-modal-content .img-wrapper {
    width: 80%;
    margin: 0 auto;
  }

  .wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .wrapper-full {
    padding-left: 20px;
    padding-right: 20px;
  }

  .page-row .left-3,
  .page-row .right-3 {
    display: none;
  }

  .page-9 {
    width: calc(100% - 30px);
  }

  .search-3 {
    width: calc(50% - 30px);
  }

  .element {
    max-width: 100%;
  }

  .proportional-wrapper {
    padding: 0;
  }
}

@media screen and (max-width: 600px) {
  .product-buttons-buy .button-main {
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 576px) {
  .pre-header .search-input-wrapper {
    right: -22px;
  }

  .home-gtr {
    margin-bottom: 20px;
    margin-left: -16px;
    margin-right: -16px;
  }

  .category-module {
    margin-bottom: 20px;
  }

  .product-overview-menu {
    text-align: center;
  }

  .product-overview-menu.fixed {
    top: auto;
    bottom: 0;
  }

  .product-overview-menu-button {
    margin-right: 16px;
  }

  .product-overview-menu-button span {
    display: none;
  }

  .lp-text {
    font-size: 0.875rem;
  }

  .lp-where-buy {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
  }

  .lp-where-buy h1 {
    margin-bottom: 18px;
    margin-top: 18px;
  }

  .lp-button .button-main {
    padding: 12px 40px;
  }

  .lp-modal {
    padding: 20px 50px 20px 20px;
  }

  #details #p_name {
    font-size: 1.125rem;
  }

  #details .details-wrapper .img-wrapper {
    width: calc(66.66666666666667% - 30px);
  }

  #details .details-block #variants .vblock-item {
    padding: 8px;
  }

  #details .details-block #variants .vblock-item img {
    width: 30px;
    height: 30px;
  }

  #details .details-icons-items svg {
    width: 80px;
    height: 80px;
  }

  #details .details-icons-items .details-item {
    margin-bottom: 10px;
  }

  #details .details-icons-items .details-item .icon-wrapper {
    height: 80px;
  }

  #details .details-icons-items .details-item span {
    display: none;
  }

  .lp-fix-menu .product-overview-menu-items {
    top: 20px;
  }

  .lp-fix-menu-button span {
    display: none;
  }

  .lp-fix-menu-button.x-close:after,
  .lp-fix-menu-button.x-close:before {
    height: 32px;
  }

  .category-sort {
    width: calc(50% - 30px);
  }

  .category-per-page {
    width: calc(50% - 30px);
  }

  .category-caption h4 > a {
    font-size: 0.875rem;
    line-height: 1.4;
  }

  .category-button .button-cat {
    font-size: 0.875rem;
    width: 100%;
    padding: 8px 24px;
  }

  .category-button-product .button-cat {
    font-size: 0.875rem;
    width: 100%;
    padding: 8px 24px;
  }

  .category-button-product .button-disabled {
    width: 100%;
    padding: 8px 24px;
  }

  #tab-specification .table {
    font-size: 0.875rem;
  }

  .product-image {
    justify-content: center;
  }

  .product-image-block {
    margin-bottom: 30px;
  }

  .product-image-main {
    width: calc(83.33333333333334% - 30px);
  }

  .product-image-additional {
    width: calc(100% - 30px);
  }

  .product-image-additional-item {
    width: calc(33.333333333333336% - 30px);
  }

  .product-buttons-buy {
    display: block;
    text-align: center;
  }

  .product-buttons-buy .button-main {
    font-size: 1.25rem;
    width: 80%;
    text-align: center;
    margin: 0 auto 20px;
  }

  .product-buttons-buy .button-buy {
    justify-content: center;
  }

  .product-buttons-buy .button-onclick {
    display: block;
  }

  .product-tab-wrapper .nav.nav-tabs {
    border-bottom-color: transparent;
  }

  .product-tab-wrapper .nav.nav-tabs li {
    float: none;
    margin: 0 0 10px;
    border-bottom: 1px solid #A5AEBE;
  }

  .product-tab-wrapper .nav.nav-tabs li a:before {
    width: 50%;
    left: 0;
    right: 0;
    margin: auto;
  }

  .product-advantages .product-row {
    justify-content: center;
  }

  .product-advantages-item {
    width: calc(100% - 30px);
  }

  .description-advantages-img-text .img-wrapper {
    float: none;
    margin-right: 0;
    width: 100%;
    margin-bottom: 30px;
  }

  #button-preorder {
    margin: 0 auto 20px;
  }

  #preorder-modal {
    margin: 20px;
  }

  .inf-img-block .img-wrapper {
    width: calc(50% - 30px);
  }

  .blog h2 {
    font-size: 1.5rem;
  }

  .blog-category-items {
    display: block;
    margin-bottom: 16px;
  }

  .blog-category-items li {
    margin-bottom: 16px;
  }

  .blog-category-items li a {
    padding: 20px;
  }

  .blog-product {
    width: calc(100% - 30px);
  }

  .iSearchBox li img {
    display: none;
  }

  .lPage-banner .img-wrapper {
    margin-left: 0;
  }

  .lPage-h1 {
    text-align: right;
  }

  .lPage-section .img-wrapper {
    width: 100%;
  }

  .lPage-section.where-by h2 {
    font-size: 2rem;
    margin-bottom: 40px;
  }

  .lPage-section.where-by p {
    font-size: 1.125rem;
  }

  .lPage-modal-content .img-wrapper {
    margin-top: 40px;
  }

  .lPage-modal-content .lPage-text p {
    font-size: 0.875rem;
  }

  .lPage-modal-content .lPage-text h2 {
    font-size: 1.375rem;
  }

  .lPage-video {
    width: calc(100% - 30px);
  }

  .lPage-button .button-where-by {
    font-size: 1.125rem;
    max-width: 254px;
    padding: 20px 30px;
  }

  .footer-item {
    width: calc(100% - 30px);
  }

  .footer-item-first {
    text-align: left;
  }

  .scroll-top {
    bottom: 70px;
  }

  .wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }

  .wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }

  .wrapper-full {
    padding-left: 16px;
    padding-right: 16px;
  }

  .main-content .left-3,
  .main-content .right-3 {
    display: none;
  }

  .product-row .left-3,
  .product-row .right-3 {
    display: none;
  }

  .category-row .left-3,
  .category-product-row .left-3,
  .category-row .right-3,
  .category-product-row .right-3 {
    width: calc(100% - 30px);
  }

  .search-3 {
    width: calc(100% - 30px);
  }
}

@media screen and (max-width: 520px) {
  .pre-header #search-input {
    width: 320px;
  }

  .pre-header .search-input-wrapper {
    right: 0;
  }
}

@media screen and (max-width: 480px) {
  .pre-header > a {
    margin-right: 2%;
  }

  .pre-header > a:nth-child(3) {
    display: none;
  }

  .pre-header .search-input-wrapper {
    right: -280%;
  }

  .header-menu.active-menu .header-menu-item.dp:after {
    left: 46%;
  }

  .lp-third-screen .lp-text-block {
    padding-top: 20%;
  }

  .category-product-item {
    width: 100%;
  }

  .category-info-description ul {
    padding-left: 20px;
  }

  #preorder {
    width: 100%;
  }

  #preorder-modal .img-wrapper {
    display: none;
  }

  .lPage-product-advantages span {
    font-size: 0.8125rem;
  }

  .lPage-modal-link:nth-child(2) {
    right: 310px;
    bottom: 135px;
  }

  .lPage-modal-link:nth-child(4) {
    bottom: 200px;
  }

  .lPage-modal-link:nth-child(6) {
    top: 130px;
    left: 200px;
  }
}

@media screen and (max-width: 420px) {
  .home-product-list li {
    font-size: 0.875rem;
  }

  .home-product-button {
    margin-top: 10px;
  }

  .home-product-button .button-default {
    font-size: 0.75rem;
  }

  .home-product-button .button-border {
    font-size: 0.75rem;
    padding: 8px 20px;
  }

  .lPage-modal-link:nth-child(2) {
    right: 270px;
    bottom: 125px;
  }

  .lPage-modal-link:nth-child(4) {
    bottom: 165px;
  }

  .lPage-modal-link:nth-child(6) {
    top: 110px;
    left: 165px;
  }
}

@media screen and (max-width: 362px) {
  .category-module-item a {
    font-size: 1.125rem;
  }

  .home-product-item:hover h2 {
    font-size: 1rem;
  }

  .home-product-item h2 {
    font-size: 1.25rem;
  }

  .home-product-info {
    padding: 8px;
  }

  .category-product-row .category-product-item {
    width: calc(100% - 30px);
  }

  .category-button-product .button-cat .cart-icon {
    width: 24px;
    height: 24px;
  }

  .category-button-product .button-disabled .disabled-icon {
    width: 24px;
    height: 24px;
  }

  .product-name h1 {
    font-size: 1.125rem;
    text-align: center;
  }

  .product-buttons-buy .button-main {
    font-size: 0.875rem;
  }

  .blog h2 {
    font-size: 1.25rem;
  }

  .footer-item li {
    font-size: 0.875rem;
  }

  .wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }

  .wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }

  .wrapper-full {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (max-width: 360px) {
  .pre-header #search-input {
    width: 300px;
  }

  .pre-header .search-input-wrapper {
    right: -220%;
  }

  .lPage-modal-link:nth-child(2) {
    right: 220px;
    bottom: 110px;
  }

  .lPage-modal-link:nth-child(4) {
    bottom: 135px;
  }

  .lPage-modal-link:nth-child(6) {
    top: 90px;
    left: 150px;
  }
}

@media screen and (min-width: 361px) and (max-width: 480px) {
  .category-button-product .button-cat .cart-icon {
    display: none;
  }

  .category-button-product .button-disabled .disabled-icon {
    display: none;
  }
}