@import "vars";
@import "smart-grid";

.header-lang {
  position: relative;
  margin-left: 20px;

  .md-block({ order: 3; })
}

.lang {
  &-button {
    background: transparent;
    border: none;
    position: relative;
    outline: none;
    z-index: 3;

    span {
      text-transform: uppercase;
      color: #ffffff;
      .fz(16);
      font-weight: 600;

      .to(1200px, { .fz(14) });
    }

    &.dp {
      &:after {
        left: 20%;
      }
    }
  }

  &-list {
    position: absolute;
    top: 34px;
    left: -7px;
    padding: 13px;
    list-style: none;
    text-align: center;
    text-transform: uppercase;
    display: none;
    background-color: @dark;
    z-index: 3;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);

    li {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    a {
      color: #ffffff;
      border-bottom: 2px solid transparent;
      .fz(16);
      font-weight: 600;
      line-height: normal;

      &:hover, &:focus {
        border-bottom: 2px solid @sky;
      }
      &:active {
        border-bottom: 2px solid #fff;
      }
    }

    &.open {
      display: block;
    }
  }

  &-overlay {
    display: none;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 2;
  }
}

.light-coloured {
  .lang {
    &-button {
      span {
        color: @dark;
      }
    }

    &-list{
      background-color: #ffffff;

      li{
        a{
          color: @dark;
        }
      }
    }
  }
}