@import "vars";
@import "smart-grid";
@import "header";
@import "language";
@import "home";
@import "html-block-home-products";
@import "product-overview";
@import "breadcrumb";
@import "category";
@import "product";
@import "video-modal";
@import "product-3d";
@import "ocfilter";
@import "account";
@import "information";
@import "blog";
@import "buyonclick";
@import "information_form";
@import "404";
@import "isearch";
@import "lending-page";
@import "lending-page-product";
@import "footer";

html {
  font-size: 16px;
}

body {
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
  max-width: 1920px;
  margin: 0 auto;

  p {
    .fz(16);
  }

  &.modal-open{
    overflow: hidden;
  }
}

.wrapper {
  .wrapper();
}

.wrapper-full {
  .wrapper-full();
}

.d-flex {
  display: flex;
}

.img-wrapper {
  img {
    max-width: 100%;
    line-height: 0;
  }
}

/******** BUTTON ********/

.button {
  &-main {
    padding: 11px 30px;
    .fz(24);
    text-transform: uppercase;
    border-radius: 50px;
    border: none;
    background-color: @sky;
  }

  &-disabled {
    background-color: @textGrey;
    color: @preHeaderBg;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-right: 30px;
    }

    .disabled-icon {
      fill: @preHeaderBg;
      width: 36px;
      height: 36px;
    }
  }

  &-default {
    .fz(14);
    background-color: @darkBlue;
    color: #ffffff;
    text-transform: none;
    padding: 8px 15px;

    .from(1024px, { &:hover {
      background-color: darken(@darkBlue, 5%);
    } });
    &-grey {
      background-color: @textGrey;

      .from(1024px, { &:hover {
        background-color: darken(@textGrey, 20%);
      } });
    }
  }

  &-border {
    box-sizing: border-box;
    .fz(14);
    background-color: #ffffff;
    color: @darkBlue;
    text-transform: none;
    padding: 8px 32px;
    border: 1px solid @darkBlue;
    transition: .5s ease;

    a {
      color: @darkBlue;
    }

    .from(1024px, { &:hover {
      border-color: @sky;
      background-color: @sky;
      transition: .5s ease;
    } &:hover a {
        color: #ffffff;
      } });
  }
}

/******** PAGE ********/
.page-row {
  .row-flex();

  .left-3, .right-3 {
    .col();
    .size(3);
    .sm-block({ display: none; })
  }
}

.page-6 {
  .col();
  .size(6);
}

.page-9 {
  .col();
  .size(9);
  .size-sm(12);
}

.page-12 {
  .col();
  .size(12);
}

.page-form-item {

  &.data {
    .form-control-label {
      z-index: 3;
    }
  }

  &.required {
    .form-control-label {
      &:after {
        content: '* ';
        color: #F00;
        font-weight: bold;
      }
    }
  }

  input.form-control, select.form-control, textarea.form-control {
    border: 2px solid @textGrey;
    box-sizing: border-box;
    border-radius: 30px;
    .fz(16);
    text-align: center;
    min-height: 40px;
    padding-left: 20px;
    padding-right: 20px;

    &:focus {
      border-color: @sky;
      box-shadow: none;
    }
  }

  .form-control-label {
    position: relative;
    left: 30px;
    top: 15px;
    background-color: #ffffff;
    padding: 0 10px;
    .fz(14);
    color: @darkBlue;
  }

  .text-danger ~ input.form-control {
    border-color: @red;
  }
}

/******** HOME ********/
.main-content {
  .row-flex();

  .left-3, .right-3 {
    .col();
    .size(3);
    .xs-block({ display: none; })
  }
}

.home-6 {
  .col();
  .size(6);
}

.home-9 {
  .col();
  .size(9);
}

.home-12 {
  .col();
  .size(12);
}

/******** PRODUCT ********/

.product-row {
  .row-flex();

  .left-3, .right-3 {
    .col();
    .size(3);
    .xs-block({ display: none; })
  }
}

.product-6 {
  .col();
  .size(6);
}

.product-9 {
  .col();
  .size(9);
}

.product-12 {
  .col();
  .size(12);
}

/******** CATEGORY ********/
.category-row, .category-product-row {
  .row-flex();

  .left-3, .right-3 {
    .col();
    .size(3);
    .size-xs(12);
  }
}

.category-row {
  .left-3 {
    .size-md(12);
  }
}

.category-6 {
  .col();
  .size(6);
  .size-md(12);
}

.category-9 {
  .col();
  .size(9);
  .size-md(12);
}

.category-12 {
  .col();
  .size(12);
}

/******** iSEARCH ********/
.search-row, .search-products{
  .row-flex();
}

.search-3{
  .col();
  .size(4);
  .size-sm(6);
  .size-xs(12);
}


/******** PROPORTIONAL ELEMENT ********/

.element {
  max-width: 820px;
  margin: 0 auto;

  .sm-block({max-width: 100%});
}

.proportional {
  position: relative;

  iframe {
    width: 100%;
    border: none;
  }

  &_content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &-wrapper{
    padding: 40px;
    background-color: @lightGrey;
    margin-bottom: 20px;

    .sm-block({ padding: 0;});
  }
}

.after-class:after {
  content: '';
  display: block;
  padding-top: 56.25%;
  height: 0;
  width: 100%;
}

.clearfix{
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}