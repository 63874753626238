@import "vars";
@import "smart-grid";

.product-overview {
  &-menu {
    background-color: #000000;
    text-align: right;
    padding: 10px 0;

    .xs-block({ text-align: center; });

    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      max-width: 1920px;
      margin: 0 auto;
      z-index: 1;

      .xs-block({ top: auto; bottom: 0; });
    }

    &-items {
      display: inline-block;
      padding-left: 0;
      margin: 0;

      .md-block({ text-align: center; });
    }

    &-item {
      display: inline-block;
    }

    &-button {
      background: none;
      border: none;
      margin-right: 69px;

      &:active svg {
        fill: @sky;
      }
      &:active {
        color: @sky;
        outline: none;
      }
      .md-block({ margin-right: 40px; });
      .xs-block({ margin-right: 16px; });

      .from(1024px, { &:hover svg, &:active svg {
        fill: @sky;
      } &:hover span, &:active span {
          color: @sky;
        } });

      svg {
        width: 32px;
        height: 32px;
        display: block;
        fill: #fff;
        margin: 0 auto 10px;
      }

      span {
        .fz(14);
        font-weight: 500;
        color: #ffffff;

        .xs-block({ display: none; });
      }

      &.active {
        svg {
          fill: @sky;
        }
        span {
          color: @sky;
        }
      }
    }
  }
}

.landing-page {
  padding: 0;
  margin: 0 auto;
  max-width: 1920px;
  border-bottom: 4px solid #a5aebe;

  img {
    line-height: 0;
  }
}

.lp {

  &-wrapper {
    margin: 0 auto;
    max-width: 1720px;
    padding: 0;
    position: relative;
  }

  &-first-screen {
    padding: 1px 75px;
    background-position: top center;
    background-repeat: no-repeat;
    height: 900px;

    .md-block({ height: 480px; background-size: cover; });

    .lp-h1 {
      text-align: right;
      margin-top: 147px;

      .md-block({ margin-top: 80px; .fz(48); text-align: center; });
    }
  }

  &-second-screen {
    display: flex;

    .to(940px, { display: block; });

    .lp-image {
      margin-top: 120px;

      .to(940px, { padding-top: 60px; width: 80%; margin: 0 auto; });
    }
  }

  &-third-screen {
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 830px;
    padding-top: 1px;

    .to(1400px, { min-height: 600px; });

    .lp-text-block {
      .to(940px, { background-color: transparent; padding-top: 15%; });
      .to(480px, { padding-top: 20%; });
    }
  }

  &-fifth-screen,
  &-sixth-screen,
  &-fourth-screen {
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 830px;
    display: flex;

    .to(940px, { display: block; });
  }

  &-fifth-screen {
    .to(940px, { display: flex; flex-direction: column; });

    .lp-text-block {
      .to(940px, { order: 1; });
    }
  }

  &-sixth-screen {
    min-height: 400px;

    .lp-text-block {
      padding-top: 3%;
      margin-top: 0;
    }
  }

  &-fourth-screen,
  &-fifth-screen,
  &-sixth-screen {
    .to(1650px, { min-height: 1px; });
  }

  &-seventh-screen,
  &-eighth-screen,
  &-ninth-screen,
  &-tenth-screen,
  &-eleventh-screen {
    background-color: #000000;
    display: flex;
    min-height: 503px;

    .md-block({ min-height: 250px });
    .to(940px, { display: block; text-align: center; });
  }

  &-eighth-screen, &-tenth-screen {
    .to(940px, { display: flex; flex-direction: column; });

    .lp-image {
      .to(940px, { order: 1; });
    }
  }

  &-h1 {
    color: #E2E3E4;
    .fz(96);
    font-weight: 700;
    text-align: center;

    .to(1400px, { .fz(82); });
  }

  &-h2 {
    .fz(40);
    font-weight: 700;
    color: #F4F5F7;
    margin-bottom: 50px;
    margin-left: 60px;

    .to(1650px, { margin-bottom: 50/1650*100*1vw; font-size: 40/1650*100*1vw; });
    .md-block({ .fz(20); });
  }

  &-image {
    width: 50%;

    .to(940px, { width: 100%; })
  }

  &-text {
    color: #f4f5f7;
    .fz(24);
    font-weight: 700;
    line-height: 35px;
    max-width: 773px;
    margin-left: 60px;

    .to(1650px, { font-size: 24/1650*100*1vw; line-height: 1.4; });
    .md-block({ .fz(16); line-height: normal });
    .xs-block({ .fz(14); });

    &-block {
      margin-top: 120px;
      width: 50%;
      padding: 1px 0;

      .to(1650px, { margin-top: 60px; });
      .to(1500px, { margin-top: 40px; });
      .md-block({ margin-top: 10px; margin-bottom: 10px; padding-right: 20px; });
      .to(940px, { padding-bottom: 20px; width: 100%; margin-top: 0; margin-bottom: 0; background-color: #000000; });
    }
  }

  &-icon {
    &-items {
      margin-top: 80px;
      margin-right: 30px;

      .to(1650px, { margin-top: 40px });

      &.horizontal {
        display: flex;
        padding-left: 5px;

        .to(940px, { margin: 30px auto 0 auto; padding-left: 15px; padding-right: 15px; });

        .lp-icon-item {
          width: 33.3%;

          .md-block({ padding: 0 10px; });

          span {
            .to(1500px, { .fz(16); });
            .mdm-block({ .fz(14); });
          }
        }
      }

      &.vertical {
        text-align: center;

        .to(1366px, { display: flex; flex-wrap: wrap; });

        .lp-icon-item {
          margin: 4% auto 0 auto;
          width: 374px;

          .to(1366px, { width: 50%; });

          .to(1650px, { margin-top: 40px; });

          .check-icon {
            .to(1650px, { margin-bottom: 20px; });
            .to(1500px, { margin-bottom: 10px; });
          }

          span {
            .to(1500px, { .fz(16); });
          }
        }
      }
    }

    &-item {
      text-align: center;

      .check-icon {
        width: 50px;
        height: 50px;
        margin-bottom: 35px;

        .md-block({ margin-bottom: 10px; });
        .sm-block({ width: 32px; height: 32px; });

        &.red {
          fill: @red;
        }
      }

      span {
        display: block;
        color: #ffffff;
        font-weight: 700;
        .fz(20);
        line-height: 29px;

        .to(1400px, { .fz(18) });
        .md-block({ line-height: normal; });
      }
    }
  }

  &-where-buy {
    background-color: #000000;
    background-repeat: no-repeat;
    min-height: 545px;
    padding-top: 1px;
    background-position: 13% center;
    background-size: cover;

    .md-block({ min-height: 450px; padding-left: 40px; padding-right: 40px; });
    .xs-block({ padding-left: 20px; padding-right: 20px; padding-bottom: 40px; });

    h1 {
      margin-top: 80px;
      margin-bottom: 60px;
      .fz(64);
      line-height: 94px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      color: #ffffff;

      .md-block({ .fz(48); margin-bottom: 40px; margin-top: 40px; });
      .xs-block({ margin-bottom: 18px; margin-top: 18px; })
    }

    p {
      .fz(24);
      font-weight: 700;
      line-height: 35px;
      max-width: 1200px;
      margin: 0 auto 60px;
      color: #ffffff;
      text-transform: uppercase;

      .md-block({ padding: 0 20px; .fz(18); line-height: normal; });

      span {
        color: @terra;
      }
    }
  }

  &-button {
    text-align: center;

    .button-main {
      background-color: @terra;
      border: 1px solid @terra;
      padding: 25px 91px;
      transition: .05s ease;

      .md-block({ padding: 16px 40px; });
      .xs-block({ padding: 12px 40px; });

      a {
        color: #ffffff;
        .fz(24);
        font-weight: 700;
        line-height: 35px;

        .md-block({ .fz(18); });
      }

      .from(1024px, { &:hover {
        background-color: #ffb2b0;
        transition: .05s ease;
      } &:hover a {
          color: @terra;
        } &:active {
            background-color: #ffffff;
            box-shadow: 0 8px 16px rgba(246, 72, 90, 0.24);
            outline: none;
          } &:active a {
              color: #f6485a;
            } })
    }
  }
}

.lp-modal {
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  overflow-y: scroll;
  background-color: #fff;
  padding: 55px;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;

  .xs-block({ padding: 20px 50px 20px 20px; });

  &-content {
    #gallery {

      .img-wrapper {
        text-align: center;
      }

      .owl-nav {
        &.disabled + .owl-dots {
          margin-top: 10px;
        }
        .owl-prev, .owl-next {
          position: absolute;
          top: 50%;

          span {
            .fz(48);
            font-weight: 700;
            color: #666;

            &:hover {
              color: #888888;
            }

          }
        }

        .owl-prev {
          left: 10px;
        }

        .owl-next {
          right: 10px;
        }
      }

      .owl-dots {
        text-align: center;
        -webkit-tap-highlight-color: transparent;

        .owl-dot {
          display: inline-block;
          zoom: 1;
          *display: inline;

          span {
            width: 10px;
            height: 10px;
            margin: 5px 7px;
            background: #D6D6D6;
            display: block;
            -webkit-backface-visibility: visible;
            transition: opacity 200ms ease;
            border-radius: 30px;
          }
        }

        .owl-dot.active span, .owl-dot:hover span {
          background: #869791;
        }
      }

    }


  }
}

#details {
  display: none;

  #p_name {
    color: @dark;

    .sm-block({ .fz(22); });
    .xs-block({.fz(18);})
  }

  .img-wrapper {
    text-align: center;
    margin-bottom: 40px;
  }

  .details {
    &-wrapper {
      .row-flex();

      .sm-block({ justify-content: center; });

      .img-wrapper {
        .col();
        .size(5);
        .size-sm(6);
        .size-xs(8);
      }
    }

    &-variants {
      margin-right: 20px;
      border-top: 1px solid #ccc;
      padding-top: 20px;
    }

    &-block {
      .col();
      .size(7);
      .size-sm(12);

      padding-right: 10px;

      .variants-name {
        font-weight: 700;
      }

      #variants {
        display: flex;

        .vblock-item {
          padding: 15px;
          border: 1px dotted @dark;
          margin-right: 15px;

          .xs-block({ padding: 8px; });

          &:hover {
            border: 1px solid @dark;
          }

          img {
            width: 50px;
            height: 50px;
            max-width: 100%;
            border-radius: 50%;

            .xs-block({ width: 30px; height: 30px; });
          }
        }
      }
    }

    &-icons-items {
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;
      text-align: center;

      svg {
        width: 256px;
        height: 256px;

        .to(1200px, { width: 180px; height: 180px; });
        .md-block({ width: 140px; height: 140px; });
        .xs-block({ width: 80px; height: 80px; })
      }

      .details-item {
        width: calc(100% / 3 - 20px);
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;

        .md-block({ width: calc(100% / 2 - 20px); });
        .sm-block({ width: calc(100% / 3 - 20px); });
        .xs-block({ margin-bottom: 10px; });

        .icon-wrapper {
          max-width: 150px;
          height: 150px;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          margin: 0 auto;

          .xs-block({ height: 80px;});

          svg {
            width: 100%;
            height: 100%;
          }

          .wheels-icon {
            width: 40%;
            height: 40%;
          }
        }

        span {
          display: block;
          text-align: center;
          .fz(14);

          .xs-block({ display: none; })
        }
      }
    }

    &-icon-item {
      width: 50%;
      text-align: center;
      margin-bottom: 20px;
    }

    &-line {
      .row-flex();

      border-bottom: 1px solid #ccc;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 3rem;
      margin-bottom: 3rem;
      line-height: 1.2;

      .sm-block({ .fz(20); margin-bottom: 20px; padding-bottom: 20px; });
    }

    &-name {
      .col();
      .size(4);
      .size-sm(12);

      color: #666666;
      .fz(26);

      .sm-block({ .fz(20); margin-bottom: 20px; font-weight: 700; });
    }

    &-value {
      .col();
      .size(8);
      .size-sm(12);

      color: #666666;
      .fz(16);
    }
  }

  #general-atts {
    margin-top: 40px;
  }
}


.lp-fix-menu {
  .product-overview-menu {
    &-items {
      position: fixed;
      top: 20%;
      right: 18px;
      z-index: 1000;
      display: block;
      text-align: center;
      background-color: #ffffff;

      .xs-block({ top: 20px; });
    }

    &-item {
      display: block;

      svg {
        fill: #666;
      }
      span {
        color: #666;
      }
    }
  }

  &-button {
    margin-bottom: 18px;
    background: none;
    border: none;

    .from(1024px, { &:hover svg {
      fill: @sky;
    } &:hover span {
        color: @sky;
      } });

    svg {
      width: 32px;
      height: 32px;
      display: block;
      fill: #fff;
      margin: 0 auto 10px;

    }

    span {
      .fz(14);
      font-weight: 500;
      color: #ffffff;

      .xs-block({ display: none;});
    }

    &.active {
      svg {
        fill: @sky;
      }
      span {
        color: @sky;
      }
    }

    &.x-close {
      position: relative;
      width: 50px;
      height: 50px;
      transition: 1s;

      .from(1024px, { &:hover {
        transform: rotate(-180deg);
        transition: .6s;
      } &:hover:before,
        &:hover:after {
          background-color: @sky;
        } });

      &:after, &:before {
        content: '';
        position: absolute;
        top: 0;
        width: 1px;
        height: 50px;
        background-color: #666666;

        .xs-block({ height: 32px;})
      }
      &:after {
        transform: rotate(45deg);
      }
      &:before {
        transform: rotate(-45deg);
      }
    }
  }
}