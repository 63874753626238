@import "vars";
@import "smart-grid";

#content-404{
  padding-bottom: 40px;

  h1, p {
    text-align: center;
  }

  .img-wrapper{
    text-align: center;
  }
}