@import "vars";
@import "smart-grid";

#information-content, #account-content {
  min-height: 44vh;
  padding-bottom: 40px;

  h1 {
    font-size: 48/1920*100*1vw;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 40px;
  }
}

.inf {
  &-text-block {

  }

  &-img-block {
    .row-flex();

    justify-content: center;

    .img-wrapper {
      .col();
      .size(2);
      .size-md(3);
      .size-mdm(4);
      .size-xs(6);

      margin-bottom: 30px;
    }
  }
}

.contact-page {
  .row-flex();

  &-block-4 {
    .col();
    .size(5);
  }

  &-block-6 {
    .col();
    .size(7);

    .element {
      max-width: 100%;
    }
  }

  &-address-items {
    .row-flex();
    margin-bottom: 10px;

    .icon-item {
      .col();
      .size(2);

      display: flex;
      justify-content: center;
      padding-top: 6px;

      svg {
        width: 30px;
        height: 30px;
        fill: @preHeaderBg;
      }

      .time-icon{
        margin-top: 34px;
      }
    }

    .text-item {
      .col();
      .size(10);

      span, a, li {
        font-weight: 500;
        .fz(24);
        line-height: 42px;
      }

      a {
        color: @preHeaderBg;
        margin-bottom: 16px;

        &:hover{
          color: @preHeaderBg;
        }
      }

      ul{
        padding-left: 0;

        li{
          list-style: none;
          padding-left: 0;
        }
      }
    }
  }
}

.account-right-column{
  .list-group-item{
    border-color: @darkBlue;
    color: @darkBlue;

    &:hover{
      border-color: @darkBlue;
      color: #fff;
      background-color: @darkBlue;
      text-shadow: none;
    }
  }
}