@import "vars";
@import "smart-grid";

.home-product {
  &-items {
    .row-flex();

    .sm-block({ justify-content: center; })
  }

  &-item {
    .col();
    .size(4);
    .size-mdm(6);
    .size-sm(12);

    position: relative;
    transition: 1s ease;
    padding: 10px;
    background-color: @lightGrey;
    margin-bottom: 30px;

    .md-block({ min-height: 450px;});

    &:hover {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
      transform: translateY(20px);

      & .img-wrapper {
        transition: 1s ease;
        filter: blur(10px);
        transform: scale(.9);
        opacity: 0.7;
      }

      & .home-product-info {
        opacity: 1;
        transition: 1s ease;
      }

      & h2 {
        .fz(18);
        transition: 1s;
      }
    }

    .xxs-block({ &:hover h2 {
      .fz(16)
    } });

    h2 {
      .fz(24);
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;

      .xxs-block({ .fz(20) });
    }

    .img-wrapper {
      transition: 1s ease;
      overflow: hidden;
      text-align: center;
    }
  }

  &-info {
    padding: 16px;
    position: absolute;
    top: 58px;
    left: 0;
    right: 0;
    opacity: 0;
    transition: 1s ease;

    .xxs-block({ padding: 8px; });
  }

  &-list {
    list-style: none;
    padding-left: 0;
    flex-basis: 320px;

    ul {
      padding-left: 0
    }

    li {
      list-style: none;
      margin-bottom: 10px;
      text-align: center;
      color: @dark;
      .fz(16);
      line-height: 1.4;
      font-weight: 500;

      .to(1440px, { line-height: normal; margin-bottom: 8px; });
      .lg-block({ .fz(14); margin-bottom: 5px; });
      .md-block({ .fz(16); });
      .to(420px, { .fz(14); });
    }
  }

  &-button {
    display: flex;
    justify-content: space-evenly;
    margin-top: 40px;

    .to(1440px, { margin-top: 20px; });
    .md-block({ margin-top: 16px; });
    .to(420px, { margin-top: 10px; });

    .button {
      &-default {
        .to(420px, { .fz(12); });
        a {
          color: #ffffff;
        }
      }
      &-border {
        .to(420px, { .fz(12); padding: 8px 20px; });
      }
    }
  }
}