@import "vars";
@import "smart-grid";

#pageform{
  width: 70%;
  margin: 0 auto;

  .mdm-block({ width: 100%;});

   .form-group{
    margin-left: 10px;
    margin-right: 10px;
  }

  textarea{
    resize: vertical;
  }
}