@import "vars";
@import "smart-grid";

#product {
  &-options {
    margin-top: 15px;
    margin-bottom: 50px;

    .input-quantity {
      margin-bottom: 20px;
    }

    .product-group {
      align-items: flex-end;

      .sm-block({ justify-content: center; flex-wrap: wrap; })
    }
  }

  &.product-group {
    flex-wrap: wrap;

    .sm-block({ display: block; });
  }
}

#tab-specification {
  .table {
    width: 60%;
    .fz(16);

    .md-block({ width: 100%; });
    .xs-block({ .fz(14) });

    thead {
      tr > td {
        background-color: @lightGrey;
        border: none;
      }
    }

    tbody {
      tr > td {
        border: none;

        &:first-child {
          width: 28%;
        }

        &:last-child {
          font-weight: 600;
          text-align: right;
        }
      }
    }
  }
}

#tab-review {
  width: 60%;

  .sm-block({ width: 100%; });

  .buttons {
    text-align: center;
  }
  .button-review {
    .fz(16);
    color: #ffffff;
    text-transform: unset;
  }

  .product {
    &-form-item {
      margin-bottom: 20px;

      input[type="text"], textarea[name="text"] {
        border: 2px solid @sky;
        border-radius: 30px;
      }

      textarea[name="text"] {
        padding: 10px 20px;
        resize: vertical;
      }

      .control-label {
        color: @sky;
        background-color: #ffffff;
        position: relative;
        top: 15px;
        left: 30px;
        padding: 0 10px;

        &-rating {
          font-weight: 600;
          //todo mod radio buttons
        }
      }
    }
  }
}

.product {

  &-main {
    padding-bottom: 40px;
  }

  &-name {
    h1 {
      font-size: 36/1920*100*1vw;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 30px;

      .md-block({ .fz(24); });
      .xxs-block({ .fz(18); text-align: center; });
    }
  }

  &-image {
    .row-flex();
    .xs-block({ justify-content: center; });

    &-block {
      margin-bottom: 60px;

      .xs-block({ margin-bottom: 30px; });
    }

    &-main {
      .col();
      .size(6);
      .size-xs(10);

      .img-wrapper {
        background-color: #ffffff;
        max-width: 543px;
        margin: 0 auto;
      }
    }

    &-additional {
      .col();
      .size(6);
      .size-xs(12);

      &-items {
        .row-flex();
      }

      &-item {
        .col();
        .size(6);
        .size-xs(4);

        background-color: #ffffff;
        margin-bottom: 30px;

        .img-wrapper {
          max-width: 225px;
          padding: 10px 0;
          border: 2px solid transparent;
          box-sizing: border-box;
          margin: 0 auto;

          .from(1024px, { &:hover {
            border: 2px solid #38A0F6;
          } })
        }
      }
    }
  }

  &-group {
    display: flex;
    justify-content: space-between;
    width: 63%;
    margin: 0 auto;

    .to(1650px, { width: 80%; });
    .to(1366px, { width: 95%; });
  }

  &-price {
    margin-right: 110/1920*100*1vw;

    .sm-block({ text-align: center; });

    .list-unstyled {
      display: inline-block;

      li {
        display: inline-block;
        h2 {
          .fz(36);
          font-weight: 600;
        }

        span {
          .fz(24);
          margin-right: 20px;
        }
      }
    }

    &-preorder-text {
      color: @textBlack;

      span {
        color: @terra;
      }
    }
  }

  &-buttons-buy {
    display: flex;
    margin-bottom: 20px;
    align-self: flex-end;

    .sm-block({ justify-content: center; });
    .xs-block({ display: block; text-align: center; });

    label[for="input-quantity"] {
      display: none;
    }
    input[name="quantity"] {
      display: none;
    }

    .button {
      &-main {
        color: #ffffff;
        font-weight: 600;
        border: none;
        padding: 11px 30px;

        .mdm-block({ .fz(20) });
        .to(600px, { .fz(18) });
        .xs-block({ .fz(20); width: 80%; text-align: center; margin: 0 auto 20px; });
        .to(362px, { .fz(14); });
      }

      &-buy {
        background-color: @sky;
        display: flex;
        align-items: center;
        margin-right: 30px;
        transition: .5s ease;

        .from(1024px, { &:hover {
          background-color: @blue;
        } });

        .mdm-block({ margin-right: 24px; });
        .xs-block({ justify-content: center; });

        span {
          margin-right: 30px;
        }

        .cart-icon {
          fill: #fff;
          width: 36px;
          height: 36px;
        }
      }

      &-disabled {
        span {
          margin-right: 0;
        }
      }

      &-onclick {
        background-color: @blue;
        padding: 19px 30px;
        transition: .5s ease;

        .xs-block({ display: block; });

        .from(1024px, { &:hover {
          background-color: @sky;
        } });

        a {
          color: #ffffff;
        }
      }
    }
  }

  &-variant {
    display: flex;
    align-items: flex-end;
    margin-right: 20px;

    .vp_title, .vbox {
      display: inline-block;
    }

    .vp_title {
      margin-right: 40px;
      margin-top: 0;
    }

    .vbox {
      margin-right: 5px;
      line-height: 0;
    }

    .vblock {
      line-height: normal;
      display: inline-block;

      &-item {
        border-radius: 50%;
        border: 1px solid #cccccc;
        width: 40px;
        height: 40px;
        overflow: hidden;
        display: block;

        a {
          display: block;
          width: 40px;
          height: 40px;

          img {
            max-width: 100%;
          }
        }
      }
    }
  }

  &-stock {
    margin: 10px 0 0;
    padding: 0;

    .stock {
      &-name {
        display: none;
      }

      &-value {
        .fz(30);
        position: relative;
        line-height: normal;
        margin-left: 60px;

        .sm-block({ .fz(24); margin-left: 40px; });

        &:before {
          content: '';
          width: 20px;
          height: 20px;
          display: block;
          background-color: transparent;
          border-radius: 50%;
          position: absolute;
          margin-right: 60px;
          top: 10px;
          left: -60px;

          .sm-block({ top: 5px; })
        }

        &.in-stock {
          &:before {
            background-color: @green;
          }
        }
        &.out-stock {
          &:before {
            background-color: @textGrey;
          }
        }
      }
    }
  }

  &-tab-wrapper {
    .col();
    .size(12);

    .nav.nav-tabs {
      //width: 60%;
      border-bottom-color: @textGrey;

      //.to(1200px, { width: 100%; });
      .xs-block({ border-bottom-color: transparent; });

      li {
        margin-bottom: 0;
        margin-right: 30/1920*100*1vw;
        //min-width: 20%;
        text-align: center;

        .sm-block({ width: auto; margin-right: 10px; });
        .xs-block({ float: none; margin: 0 0 10px; border-bottom: 1px solid @textGrey; });

        a {
          position: relative;
          border: none;
          .fz(24);
          color: @textBlack;
          font-weight: 600;

          .sm-block({ .fz(18); });

          &:before {
            content: '';
            display: block;
            width: 100%;
            height: 5px;
            background-color: transparent;
            position: absolute;
            bottom: -3px;
            left: 0;
            border-radius: 10px;

            .xs-block({ width: 50%; left: 0; right: 0; margin: auto; });
          }
        }

        &.active {
          a {
            border: none;

            &:before {
              background-color: @sky;
            }
          }
        }
      }
    }
  }

  &-advantages {
    .product-row {
      .xs-block({ justify-content: center; });
    }

    &-item {
      .col();
      .size(3);
      .size-md(4);
      .size-sm(6);
      .size-xs(12);

      margin-bottom: 40px;
    }

    &-text {
      background-color: @textBlack;
      padding: 15px;

      h3 {
        color: #ffffff;
        margin-top: 0;
        .fz(22);
        font-weight: 700;
        text-transform: uppercase;
      }
      p {
        color: #ffffff;
        .fz(16);
      }
    }
  }
}

#tab-description {
  p {
    .fz(16);
    text-indent: 30px;
    font-weight: normal;
    color: #000000;
    line-height: 30px;

    .md-block({ line-height: 25px; });
  }
}

.description {
  .row-flex();
  padding: 11px 30px 40px;

  h3 {
    .fz(24);
    font-weight: 600;
    color: #000000;
    margin-top: 0;
    text-indent: 30px;

    .sm-block({ text-indent: 0;});
  }
  &-text {
    .col();
    .size(8);
    .size-md(12);
  }
  &-image {
    .col();
    .size(4);

    background-color: #ffffff;

    .md-block({ display: none; });
  }
  &-advantages {
    &-block {
      background-color: @lightGrey;
      padding: 30px 30px 60px;

      h2 {
        .fz(36);
        font-weight: 600;
        margin-bottom: 40px;
        text-transform: uppercase;

        .md-block({ .fz(24); });
      }
    }
    &-wrap {
      .row-flex();

      h3 {
        .fz(24);
        font-weight: 600;
        color: #000000;
        margin-top: 0;
        text-indent: 30px;

        .sm-block({ text-indent: 0;});
      }
    }
    &-image {
      .col();
      .size(4);
      .size-md(6);
      .size-sm(12);

      margin-bottom: 30px;
      background-color: #ffffff;

      .md-block({ &:nth-child(2) {
        display: none;
      } });
      .sm-block({ text-align: center; });

      &.image-3 {
        .size(4);
        .size-sm(12);
      }
    }
    &-text {
      .col();
      .size(4);
      .size-md(6);
      .size-sm(12);

      margin-bottom: 30px;

      &.long-text {
        .col();
        .size(8);
        .size-sm(12);

        h3 {
          margin-top: 0;
        }
      }
    }
    &-img-text {
      .col();
      .size(4);
      .size-sm(12);

      margin-bottom: 30px;

      .img-wrapper {
        margin-bottom: 25px;

        .sm-block({ float: left; margin-right: 10px; width: 200px; margin-bottom: 0; });
        .xs-block({ float: none; margin-right: 0; width: 100%; margin-bottom: 30px;});
      }
    }
  }
  &-parameters {
    padding-top: 60px;
    padding-bottom: 60px;

    .sm-block({ padding: 20px 0;});

    &-wrap {
      border: 1px solid #FB8200;
      background-color: #FEF3E7;
      padding: 40px 60px;
      border-radius: 10px;
      margin: 0 auto;
      width: 90%;

      .sm-block({ padding: 20px; width: 100%;});
    }

    h3 {
      .fz(24);
      color: #fb8200;
      text-indent: 43px;

      .sm-block({.fz(20);  text-indent: 0;});
    }
    ul {
      padding-left: 0;
    }
    li {
      .fz(18);
      list-style: none;
      color: #4a4b57;
      line-height: 40px;
      position: relative;
      text-indent: 20px;

      .md-block({ line-height: 30px;});
      .sm-block({ .fz(16); line-height: 1.4; text-indent: 0;});

      &:before {
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid #fb8200;
        margin-right: 10px;
      }

      &:after {
        content: '';
        display: block;
        width: 7px;
        height: 4px;
        border-left: 1px solid #fb8200;
        border-bottom: 1px solid #fb8200;
        position: absolute;
        top: 15px;
        left: 25px;
        transform: rotate(-45deg);

        .md-block({ top: 10px;});
        .sm-block({ top: 5px; left: 5px;});
      }
    }
  }
}

#button-preorder {
  margin-right: 0;
  margin-left: 20px;

  .xs-block({ margin: 0 auto 20px; });
}

#preorder {
  width: 70%;
  text-align: center;

  .to(480px, { width: 100%; });

  &-modal {
    display: none;
    position: fixed;
    top: 20%;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #ffffff;
    z-index: 10;
    max-width: 520px;
    padding: 15px;
    border-radius: 4px;

    .xs-block({ margin: 20px; });

    .img-wrapper {
      width: 30%;

      .to(480px, { display: none; });
    }

    .preorder-block {
      display: flex;
    }

    .preorder-modal {
      &-close {
        text-align: center;
        .fz(22);
        font-weight: 700;
        position: relative;
        color: @textBlack;

        span {
          cursor: pointer;
          position: absolute;
          width: 30px;
          height: 30px;
          top: -28px;
          right: -28px;
          border-radius: 50%;
          border: 3px solid #ffffff;
          background-color: @dark;
          box-shadow: 0 0 3px @dark;

          &:after, &:before {
            content: '';
            display: block;
            position: absolute;
            width: 16px;
            height: 3px;
            border-radius: 3px;
            background-color: #ffffff;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
          }

          &:after {
            transform: rotate(45deg);
          }

          &:before {
            transform: rotate(-45deg);
          }
        }
      }
    }

    fieldset {
      width: 90%;
      margin: 0 auto;

      .page-form-item {
        input, textarea {
          margin-top: 15px;
        }

        textarea {
          resize: vertical;
          margin-bottom: 15px;
        }
      }
    }
  }

  .error {
    border-color: @terra;
    box-shadow: 0 0 10px @terra;
  }
}

.preorder-modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .3);
  z-index: 9;
}

img.mfp-img {
  background-color: #ffffff;
  padding: 0;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: @dark;
  right: 2px;
}

.mfp-counter {
  right: 6px;
}

.product-image-additional {
  .sm-block({ display: none; });
}

.product-image-main {
  .sm-block({ display: none; });
}

.product-image-mobile.owl-carousel {
  display: none;
  .sm-block({ display: block; });

  .img-wrapper {
    text-align: center;
  }

  .owl-nav.disabled + .owl-dots {
    margin-top: 10px;
  }

  .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;

    .owl-dot {
      display: inline-block;
      zoom: 1;
      *display: inline;

      span {
        width: 10px;
        height: 10px;
        margin: 5px 7px;
        background: #D6D6D6;
        display: block;
        -webkit-backface-visibility: visible;
        transition: opacity 200ms ease;
        border-radius: 30px;
      }
    }

    .owl-dot.active span, .owl-dot:hover span {
      background: #869791;
    }
  }
}


