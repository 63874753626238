@import "vars";
@import "smart-grid";

.product{
  &-3d{
    max-width: 1440px;
    margin: 0 auto;
    border: 3px solid #ffffff;

    img{
      max-width: 100%;
    }

    &-modal{
      display: none;

      &.active{
        display: block;
      }
    }
  }
}

.cloudimage-360{
  &-prev,
  &-next{
    padding: 8px;
    background: transparent;
    border: none;
    display: none;
    position: absolute;
    z-index: 100;
    top: calc(50% - 15px);

    &:focus{
      outline: none;
    }

    &.not-active{
      opacity: 0.4;
      cursor: default;
    }

    svg{
      fill: @textGrey;

      &:hover{
        fill: @preHeaderBg;
      }
    }
  }

  &-prev {
    left: 20px;
  }
  &-next {
    right: 20px;
  }
}

.cloudimage-inner-box{
  line-height: 0;

  img{
    bottom: 3%!important;
  }
  canvas + div{
    top: 30px!important;
    right: 30px!important;
  }
}