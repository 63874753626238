@import "vars";

.fancybox-skin {
  border-radius: 8px;
  background-color: #ffffff !important;
  box-shadow: rgba(000, 000, 000, 0.9) 0 0 2px, inset rgba(255, 255, 255, 0.4) 0 0 0;
  text-decoration: none;
  padding: 20px;
}

.request-popup {
  .fancy_heading {
    h3 {
      color: @dark;
      margin: 15px 0 0 0;
      font-weight: 700;
      text-align: center;
      .fz(22);
    }
  }

  .buyonclick {
    &-box {
      display: flex;
      padding: 0 10px;
    }

    &-form {
      width: 59%;
    }
  }

  .product-buyonclick {
    &-title {
      padding: 10px;
    }

    &-img {
      width: 40%;
      margin-right: 10px;

      .img-wrapper {
        width: 120px;
      }
    }
  }

  .page-form-item {
    textarea {
      resize: vertical;
    }
  }
}

.success-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  background: #fff;
  border-radius: 8px;
  box-shadow: rgba(000, 000, 000, 0.9) 0 0 2px, inset rgba(255, 255, 255, 0.4) 0 0 0;
  text-decoration: none;
  vertical-align: middle;
  min-width: 300px;
  padding: 20px;
  width: 300px;
  min-height: 100px;
  margin-top: -100px;
  margin-left: -150px;
  text-align: center;
  font-size: 1.25em;

  p{
    color: @dark;
  }
}
