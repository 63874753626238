@import "vars";
@import "smart-grid";

body.oHidden {
  overflow: hidden;
}


.lPage {

  /* Landing color */
  @colorTitle: @yellow;
  @colorText: #fff;
  @iconFill: @yellow;

  overflow: hidden;

  &-relative {
    position: relative;

    &:after {
      content: '';
      display: block;
      padding-top: 49.23%;
      width: 100%;

      .lg-block({ padding-top: 54.5%; });
      .to(1080px, { padding-top: 60%; });
      .mdm-block({ padding-top: 65%; });
      .sm-block({ display: none; });
    }

    .lPage-text {
      flex: 0 0 50%;

      .to(1440px, { flex: 0 0 70% });
      .lg-block({ flex: 0 0 74% });
    }
  }

  &-banner {
    position: relative;
    background-image: linear-gradient(5deg, #2C2D2D 30%, #000 30.3% 100%);
    background-position: top right;
    display: flex;
    padding: 40px;

    .img-wrapper {
      margin-left: 10vw;

      .xs-block({ margin-left: 0; })
    }
  }

  &-h1 {
    font-family: 'Oswald', sans-serif;
    font-size: (144/1903 * 100) * 1vw;
    color: #0A6CE2;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 15vw;
    flex-grow: 1;
    text-align: center;
    flex-basis: 600px;

    .xs-block({ text-align: right; })
  }

  &-section {
    padding: 50px 35px 60px;
    background-color: #000;
    display: flex;

    .lg-block({ padding: 30px 35px 40px; });
    .sm-block({ display: block; });

    .img-wrapper {
      flex: 1 0 50%;
      text-align: center;

      .lg-block({ flex: 1 0 40%; padding-left: 15px; });
      .sm-block({ width: 80%; margin: 0 auto; });
      .xs-block({ width: 100%; });

      &.lPage-modal-btn {
        .sm-block({ margin: 20px auto 0; })
      }
    }

    &.size {
      flex-wrap: wrap;
      h2 {
        flex-basis: 100%;
        text-align: center;
      }
    }

    &.video {
      display: block;

      h2 {
        text-align: center;
      }

      .page-row {
        .mdm-block({ justify-content: center; });
      }
    }

    &.where-by {
      display: block;

      h2 {
        text-align: center;
        color: @colorTitle;
        font-weight: bold;
        .fz(44);
        text-transform: uppercase;
        margin-bottom: 44px;

        .xs-block({ .fz(32); margin-bottom: 40px; });
      }

      p {
        .fz(24);
        font-weight: bold;
        color: #ffffff;
        line-height: 1.5;
        max-width: 1200px;
        margin: 0 auto;

        .xs-block({ .fz(18); });

        span {
          color: @colorTitle;
        }
      }
    }
  }

  &-bg {
    background-image: linear-gradient(-5deg, #2C2D2D 32%, #000 32.3% 100%);
    background-position: top left;

    .sm-block({ background-image: linear-gradient(-5deg, #2C2D2D 25%, #000 25.3% 100%); });
  }

  h2 {
    color: @colorTitle;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 12px;
    margin-bottom: 40px;

    .to(860px, { margin-bottom: 20px; });
  }

  &-text {
    padding-left: (155/1920 * 100) * 1vw;
    padding-top: (90/1920 *100) * 1vw;
    flex: 1 0 50%;

    .lg-block({ flex: 1 0 60% });
    .md-block({ padding-left: 0; });

    p {
      color: @colorTitle;
      .fz(18);
      font-weight: bold;
      text-transform: uppercase;
    }

    p.text {
      color: @colorText;
      .fz(16);
      font-weight: 500;
      text-transform: unset;
      line-height: 30px;

      .to(860px, { line-height: 1.5; });
      .sm-block({ .fz(14) });
    }
  }

  &-product-color {
    .sm-block({ text-align: center; });

    .color-product {
      margin-top: 40px;
      display: inline-block;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      border: 1px solid #ffffff;
      cursor: pointer;

      .md-block({ margin-top: 20px; width: 38px; height: 38px; });
      .sm-block({ text-align: center; margin-bottom: 20px; });

    }
  }

  &-product-image {
    position: absolute;
    right: 10%;
    bottom: 0;

    .to(1440px, { right: 35px; });
    .sm-block({ position: relative; right: 0; left: 0; top: 0; bottom: 0; margin: auto; });

    .img-wrapper {
      position: relative;
      width: (1130/1903) * 100 * 1vw;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background: linear-gradient(to top, #000, transparent);
      }
    }
  }

  &-product-advantages {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .check-icon {
      fill: @iconFill;
      width: 30px;
      height: 30px;
      margin-bottom: 20px;
    }

    span {
      display: block;
      color: #ffffff;
      .fz(16);
      font-weight: 500;

      .to(480px, { .fz(13); });
    }
  }

  &-item {
    text-align: center;
    padding-top: 40px;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &-modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 11;
    background-color: #000;
    padding-top: 30px;
    padding-left: 140/1920 * 100 * 1vw;
    padding-right: 140/1920 * 100 * 1vw;

    .lg-block({ padding: 30px 65px 0; });
    .md-block({ padding: 20px 10px 20px 30px; });

    &.active {
      display: block;

      .mCSB_container {
        display: flex;

        .sm-block({ display: block; });
      }
    }

    &-btn {
      position: relative;
    }

    &-link {
      display: flex;
      width: 50px;
      height: 50px;
      border: 1px solid @iconFill;
      border-radius: 50%;
      justify-content: center;
      flex-flow: row wrap;
      align-content: center;
      position: absolute;
      cursor: pointer;

      .mdm-block({ width: 40px; height: 40px; &:hover &-elem {
        width: 24px;
        height: 24px;
      } });

      &:hover &-elem {
        width: 30px;
        height: 30px;
        border-radius: 3px;
        transition: .6s ease;
      }
      &:hover &-elem .square-icon {
        opacity: 1;
      }

      &-elem {
        width: 10px;
        height: 10px;
        background-color: @iconFill;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .8s ease;

        .square-icon {
          fill: #fff;
          width: 60%;
          height: 60%;
          opacity: 0;
        }
      }

      &:nth-child(2) {
        top: 0;
        left: 0;
        right: 360px;
        bottom: 145px;
        margin: auto;

        .md-block({ right: 290px; bottom: 130px; });
        .to(900px, { right: 255px; bottom: 120px; });
        .sm-block({ right: 350px; bottom: 150px; });
        .to(480px, { right: 310px; bottom: 135px; });
        .to(420px, { right: 270px; bottom: 125px; });
        .to(360px, { right: 220px; bottom: 110px; });
      }

      &:nth-child(3) {
        top: auto;
        left: 0;
        right: 10px;
        bottom: 80px;
        margin: auto;

        .md-block({ right: 0; bottom: 60px; });
      }

      &:nth-child(4) {
        top: auto;
        left: 30px;
        right: 0;
        bottom: 230px;
        margin: auto;

        .to(1200px, { bottom: 195px; });
        .md-block({ left: 40px; bottom: 180px; });
        .sm-block({ left: 25px; bottom: 230px; });
        .to(480px, { bottom: 200px; });
        .to(420px, { bottom: 165px; });
        .to(360px, { bottom: 135px; });
      }

      &:nth-child(5) {
        top: 0;
        left: 0;
        right: 0;
        bottom: 40px;
        margin: auto;

        .md-block({ });
      }

      &:nth-child(6) {
        top: 150px;
        left: 230px;
        right: 0;
        bottom: auto;
        margin: auto;

        .md-block({ top: 120px; left: 190px; });
        .to(900px, { top: 105px; left: 170px; });
        .sm-block({ top: 145px; left: 210px; });
        .to(480px, { top: 130px; left: 200px; });
        .to(420px, { top: 110px; left: 165px; });
        .to(360px, { top: 90px; left: 150px; });
      }
    }

    &-close {
      margin: 0 auto;
      width: 50px;
      height: 50px;
      background-color: @iconFill;
      position: relative;
      border-radius: 50%;
      cursor: pointer;
      flex: 0 0 auto;
      z-index: 2;

      &:after,
      &:before {
        content: '';
        display: block;
        width: 3px;
        height: 24px;
        background-color: #ffffff;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
      &:after {
        transform: rotate(45deg);
      }
      &:before {
        transform: rotate(-45deg);
      }
    }

    &-content {
      margin-top: 20px;
      max-width: 100vw;
      height: calc(100vh - 110px);

      .img-wrapper {
        margin-top: 20px;
        display: flex;
        align-items: center;

        .md-block({ flex: 0 1 50%; });
        .sm-block({ width: 80%; margin: 0 auto; });
        .xs-block({ margin-top: 40px; });
      }

      .lPage-text {
        flex: 0 1 40%;
        padding-left: 16px;

        .md-block({ flex: 0 1 50%; });

        p {
          .xs-block({ .fz(14); });
        }
        h2 {
          .xs-block({ .fz(22); });
        }
      }
    }
  }

  &-video {
    .col();
    .size(6);
    .size-mdm(10);
    .size-xs(12);

    .mdm-block({ margin-bottom: 30px; })
  }

  &-button {
    .button-where-by {
      display: block;
      max-width: 344px;
      color: #ffffff;
      background-color: @colorTitle;
      text-align: center;
      padding: 30px 30px;
      font-weight: bold;
      margin: 60px auto 10px;

      .xs-block({ .fz(18); max-width: 254px; padding: 20px 30px; });
    }
  }
}

.section3d {
  background-color: #000000;
}

.warning {
  font-weight: bold;
}

.screen-size {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.content-wrapper {
  max-width: 1200px;
  position: relative;
}

.content-wrapper:after {
  content: "";
  padding-top: 50%;
  display: block;
}