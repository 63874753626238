@import "vars";
@import "smart-grid";

#account-content {
  .page-form-item {
    margin-bottom: 20px;
  }
}

.page-main {
  .buttons {
    text-align: right;
  }

  .button-default {
    a {
      color: #ffffff;
    }
  }
}

#login-content {
  .page-col {
    .col();
    .size(6);
    .size-sm(12);

    display: flex;

    .well {
      width: 100%;
    }
  }
}

#order-content {
  min-height: 44vh;

  .fa.fa-eye {
    .fz(22);
    color: @sky;
    margin-right: 15%;

    .from(1024px, { &:hover {
      color: @darkBlue;
    } });
  }
  .order-table-button {
    vertical-align: middle;

    .fa.fa-reply {
      .fz(24);
      color: @terra;

      .from(1024px, { &:hover {
        color: @red;
      } })
    }

    .cart-fill-icon {
      width: 28px;
      height: 28px;
      fill: @sky;
      margin-right: 10px;

      .from(1024px, { &:hover {
        fill: @darkBlue;
      } })
    }
  }
}

#register-content {
  .page-form-item {
    margin-bottom: 5px;
    width: 80%;
  }
}

#return-content {
  .fa.fa-eye {
    .fz(22);
    color: @sky;
    margin-right: 15%;

    .from(1024px, { &:hover {
      color: @darkBlue;
    } });
  }
}