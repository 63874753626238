@import "vars";

.product-modal{
  display: none;
  position: fixed;
  top: 12vw;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  max-width: 820px;
  z-index: 11;
  overflow: hidden;
  padding: 0 10px;

  &.active{
    display: block;
  }

  &-close{
    border: 2px solid @preHeaderBg;
    border-radius: 50%;
    background-color: #fff;
    position: relative;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after,
    &:before{
      content: '';
      width: 14px;
      height: 2px;
      position: absolute;
      background: @preHeaderBg;
    }

    &:after{
      transform: rotate(45deg);
    }

    &:before{
      transform: rotate(-45deg);
    }
  }

  .modal-line{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  .proportional{
    display: none;
    border: 3px solid #ffffff;

    &.active{
      display: block;
    }
  }
}

.product-icon-line{
  display: flex;
  align-items: center;
  padding: 0 30px;
}

.view-360,
.video-icon{
  cursor: pointer;
  width: 40px;
  height: 40px;
  fill: @sky;
  margin-right: 20px;
}

.overlay{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0, .5);
  display: none;
  z-index: 10;

  &.active{
    display: block;
  }
}

