@import "vars";
@import "smart-grid";

footer {
  background-color: @lightGrey;
  padding-top: 50px;
  padding-bottom: 35px;
}

.footer {
  .row-flex();

  &-item {
    .col();
    .size(6);
    .size-xs(12);

    h5 {
      .fz(16);
    }

    li {
      .fz(16);
      font-weight: 600;
      line-height: 27px;

      a {
        color: @textBlack;

        &:hover {
          color: @sky;
        }
      }

      .xxs-block({ .fz(14) });
    }

    &-first {
      text-align: right;

      .xs-block({ text-align: left; })
    }
  }
}

.list-social {
  padding-left: 0;
  list-style: none;

  &-item {
    display: inline-block;
    margin-right: 16px;

    &:first-child a:hover {
      background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    }
    &:nth-child(2) a:hover {
      background-color: #ff0000;
    }
    &:nth-child(3) a:hover {
      background-color: #3b5998;
    }
    &:last-child a:hover {
      background-color: #E60023;
    }

    a {
      width: 30px;
      height: 30px;
      background-color: #c4c4c4;
      border-radius: 50%;
      text-align: center;
      display: block;
      position: relative;

      &:hover {
        animation: animate ease 0.6s forwards;

        & svg {
          fill: #fff;
        }
      }
    }

    svg {
      width: 16px;
      height: 16px;
      fill: #4B4C4C;
      vertical-align: middle;
      transition: all ease 0.2s;
    }
  }
}

@keyframes animate {
  0%, 20% {
    transform: scale(1);
    border-radius: 40px;
    background-color: #9E9E9E;
  }
  30% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
    border-radius: 10px;
  }
  60%, 100% {
    transform: scale(1);
    border-radius: 5px;
  }
}

.copyright {
  text-align: center;
  padding-top: 25px;
  color: @textGrey;
  margin-bottom: 0;
  .fz(12);
  font-weight: 600;
  text-transform: uppercase;
}

.scroll-top {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 40px;
  height: 40px;
  border: 1px solid;
  color: @darkBlue;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 2px 6px rgba(40, 57, 99, 0.2);
  cursor: pointer;
  display: none;

  .xs-block({ bottom: 70px;});

  &:hover {
    color: @sky;
    box-shadow: 0 2px 6px rgba(19, 90, 206, 0.2);

    &:after{
      color: @sky;
    }
  }

  &:after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    color: @darkBlue;
    border-top: 3px solid;
    border-right: 3px solid;
    position: absolute;
    top: 14px;
    left: 0;
    right: 0;
    margin: auto;
    transform: rotate(-45deg);
  }
}

