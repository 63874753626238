@import "vars";
@import "smart-grid";

.iSearchBox {
  padding: 10px;
  background: white;
  display:none;
  box-shadow: 0 0 2px #A5AEBE;
  z-index:10003;
  position:absolute;
  margin-top:1px;
  overflow: inherit !important;
  left: 2%;
  text-align: left;

  li{
    list-style:none;
    margin:0;
    cursor:pointer;
    padding: 0 10px;
    border-bottom: 1px solid #F5F5F5;

    &:last-child {
      margin-bottom: 0;
      font-size: 13px;
      padding: 8px 0;
    }

    &.activekey{
      background-color: #F5F5F5;
      transition: .8s ease
    }
    &:hover{
      background-color: #F5F5F5;
      transition: .8s ease;
    }

    &:hover h3,
    &:hover .iSearchModel,
    &:hover .iSearchPrice{
      color:#003264;
    }

    &.iSearchNoResults,
    &.iSearchNoResults:hover{
      box-shadow: 0 1px 1px #fff;
      border-top:1px solid #fff;
      cursor: default;
      text-align: center;
    }

    img {
      padding: 10px 10px 10px 0;
      display: inline-block;

      .xs-block({ display: none;});
    }

    .iSearchItem {
      min-height: 50px;
      display: inline-block;
    }

    .iSearchPrice {
      font-weight: bold;
      color: #333;
      font-size: 12px;
      display: block;
      padding: 10px 0;

      span{
        font-size: 16px;
      }

      .specialPrice {
        color:red;
        text-decoration:line-through;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .iSearchModel {
      color: #555;
      font-size: 11px;
      font-weight: normal;
    }

    h3 {
      padding: 0;
      margin: 0;
      padding-top: 10px;
      overflow: hidden;
      white-space:normal !important;
      line-height: 18px;
      font-size: 14px;
      font-weight: normal;
      color: #003264;
    }
  }

  ul {
    list-style:none;
    padding:0;
    margin:0;
    margin-bottom: 10px;

    li.iSearchHeading {
      margin: 0 0 10px 0;
      font-size: 18px;
      padding-left: 5px;
      position: relative;

      &:hover {
        border-color: white;
        cursor: default;
        box-shadow: none;
      }
    }
  }

  .iSearchSpecial{
    display: inline-block;
    margin-left: 20px;
    font-size: 16px;
  }

  .iSearchViewAllResults {
    color: #333;
    margin-bottom: 0;
    text-align: center;
    line-height: 20px;
  }
}

.iSearchBoxWrapper {
  position:relative;

  .clearfix{
    zoom:1;
    &:before{
      content:"";
      display:table;
    }
    &:after {
      content:"";
      display:table;
      clear:both;
    }
  }

  #iSearchBoxLoadingImage {
    background-image: url(../image/isearch/loading.gif);
    width: 16px;
    height: 16px;
    visibility: hidden;
    display: none !important;
  }
}

/* Fixes the nivo slider overlap */
.slideshow .nivo-controlNav {
  z-index:10;
}

.search {
  &-main {
    background-color: @lightGrey;
    border-bottom: 4px solid @textGrey;
    padding-bottom: 40px;
  }

  &-result {
    .page-form-item {
      position: relative;
      margin-top: 20px;

      #input-search{
        background-color: @lightGrey;
      }

      .form-control {
        text-align: left;
        width: 70%;
        display: inline-block;
        margin-right: 3%;

        &-label {
          position: absolute;
          top: -10px;
          background-color: transparent;
          z-index: 1;

          &:before{
            content: '';
            position: absolute;
            display: block;
            height: 3px;
            background-color: @lightGrey;
            width: 100%;
            top: 10px;
            left:0;
            z-index: -1;
          }
        }
      }

      .button-default {
        display: inline-block;
        border: 2px solid @darkBlue;
      }
    }
  }

  &-products {
    margin-top: 40px;
  }

  &-product {
    .col();
    .size(3);
    .size-mdm(6);

    background-color: #fff;
    margin-bottom: 30px;
    padding: 20px;

    .from(1024px, { &:hover {
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
      transition: .6s ease
    } });

    .price {
      text-align: center;
      font-weight: 700;
      .fz(24);

      &-old{
        .fz(18);
        font-weight: 400;
        text-decoration: line-through;
        color: @red;
      }
    }

    .button-group {
      text-align: center;

      .button-cat {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        .from(1024px, {&:hover{
          background-color: @darkBlue;}});

        .cart-icon {
          width: 22px;
          height: 22px;
          fill: #fff;
          margin-right: 10px;
        }

        span {
          color: #ffffff;
          .fz(18);
        }
      }
    }
  }
}