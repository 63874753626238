@import "vars";
@import "smart-grid";

#blog-content {
  h2 {
    text-align: center;
    color: @textBlack;
    font-weight: 700;
    .fz(48);
    text-transform: uppercase;
    margin-bottom: 34px;
  }

  .pagination-row {
    .row-flex();

    flex-basis: 100%;
  }

  .pagination-block {
    .col();
    .size(6);

    text-align: right;
  }

  .results {
    .col();
    .size(6);

    line-height: 32px;
  }
}

#content-blog {
  .thumbnails {
    margin-top: 20px;

    .image-additional {
      float: none;
      max-width: 100%;

      &.img-wrapper {
        .col();
        .size(3);

        img {
          max-width: 100%;
        }
      }
    }
  }

  .img-wrapper {
    margin-bottom: 20px;

    &-blog {
      display: flex;
      margin: 15px auto;

      img {
        max-width: 100%;
      }

      &.img {
        &-1 {
          img {
            width: 100%;
          }
        }
        &-2 {
          img {
            width: 50%;
          }
        }
        &-3 {
          img {
            width: 33.333%;
          }
        }
        &-4 {
          img {
            width: 25%;
          }
        }
      }
    }
  }
}

body.newsblog-category {
  background-color: @lightGrey;
}

.newsblog-article {
  li {
    .fz(16);
    line-height: 20px;
  }
}

article.page-row {
  .img-wrapper {
    text-align: center;
  }
}

.blog {
  margin-bottom: 80px;
  & + footer {
    border-top: 2px solid @textGrey;
  }

  h2 {
    text-align: center;
    font-weight: 700;
    .fz(36);
    text-transform: uppercase;
    margin-bottom: 40px;
    margin-top: 40px;
    color: @textBlack;

    .sm-block({ .fz(28); });
    .xs-block({ .fz(24); });
    .xxs-block({ .fz(20); });
  }

  &-items {
    .row-flex();
    .mdm-block({ justify-content: center; });
  }

  &-item {
    .col();
    .size(4);
    .size-mdm(12);
  }

  &-caption {
    h3 {
      font-weight: 600;
      .fz(24);
      text-transform: uppercase;
      text-align: left;
      margin-bottom: 24px;

      .sm-block({ .fz(20); });

      a {
        color: @textBlack;
      }
    }

    p {
      font-weight: 500;
      color: @textBlack;
      .fz(14);

      .sm-block({ .fz(12); });
    }
  }

  &-button {
    text-align: right;

    button {
      border: none;
      background-color: transparent;
      .fz(14);

      span {
        color: @terra;

        &:hover {
          text-decoration: underline;
        }
      }

      .fa {
        .fz(16);
      }
    }

    &-home{
      margin-top: 53px;

      .button-main{
        padding: 16px 23px;
        box-sizing: border-box;
        color: #ffffff;
        font-weight: 700;
        text-transform: uppercase;
        .fz(14);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 290px;
        border: 1px solid @sky;

        .from(1024px, {&:hover{
          background-color: #E5EFFF;
          border: 1px solid #0A6CE2;
          box-shadow: 0 8px 16px rgba(10, 108, 226, 0.2);
          color: #0A6CE2;
        }
        &:hover .blog-icon{
          fill: #0A6CE2;
        }});

        &:active, &:focus{
          background-color: #fff;
          border: 1px solid #0A6CE2;
          box-shadow: 0 4px 8px rgba(10, 108, 226, 0.24);
          color: #0A6CE2;
        }

        &:active .blog-icon, &:focus .blog-icon{
          fill: #0A6CE2;
        }
      }

      .blog-icon{
        width: 24px;
        height: 24px;
        margin-right: 12px;
        fill: #fff;
      }
    }
  }

  &-category {
    .row-flex();

    justify-content: center;

    &-items {
      .col();
      .size(3);
      .size-md(12);

      .md-block({ display: flex; justify-content: space-between; });
      .xs-block({ display: block; margin-bottom: 16px; });

      padding-left: 0;

      li {
        list-style-type: none;
        margin-bottom: 40px;
        text-align: center;
        background-color: #ffffff;

        .xs-block({ margin-bottom: 16px; });

        a {
          display: block;
          .fz(24);
          font-weight: 700;
          color: @textGrey;
          padding: 35px;

          .md-block({ padding: 32px 8%; });
          .sm-block({ padding: 32px 35px; });
          .xs-block({ padding: 20px; });
        }

        .from(1024px, { &:hover, &:active {
          background-color: @sky;
        } &:hover a, &:active a {
            color: #ffffff;
          } });
      }

    }

    &-article {
      &-items {
        .col();
        .size(9);
        .size-md(12);
      }

      &-item {
        padding: 20px;
        background-color: #ffffff;
        margin-bottom: 35px;

        &-wrapper {
          .row-flex();

          .img-wrapper {
            .col();
            .size(4);
          }
        }

        &-caption {
          .col();
          .size(8);

          h4 {
            .fz(24);
            font-weight: 700;

            a {
              color: @textBlack;
            }
          }

          .read-more {
            float: right;
            color: @sky;
            text-decoration: underline;
          }
        }
      }
    }
  }

  &-product {
    .col();
    .size(3);
    .size-md(4);
    .size-sm(6);
    .size-xs(12);

    .product-thumb {
      padding: 10px;
      border: none;

      .from(1024px, { &:hover {
        box-shadow: 0 4px 20px rgba(0, 0, 0, .25);
      } });

      .img-wrapper {
        text-align: center;
      }
    }

    .caption {
      min-height: 50px;
      text-align: center;

      h4 {
        text-align: center;

        a {
          color: @textBlack;
          font-weight: 500;
        }
      }
    }

    &-button {

      .button-main {
        display: flex;
        align-items: center;
        margin: 0 auto;

        .from(1024px, { &:hover {
          background-color: darken(@sky, 20%);
        } });
      }

      .button-disabled span{
        margin-right: 5px;
      }

      svg {
        width: 28px;
        height: 28px;
        fill: #fff;
      }

      span {
        .fz(18);
        color: #ffffff;
        font-weight: 600;
        margin-left: 5px;
      }
    }
  }

  &-text-related {
    border-bottom: 1px solid @lightGrey;
  }

  &-related-articles {
    border-bottom: 1px solid @lightGrey;

    &-thumb {
      .row-flex();
    }

    &-caption {
      .col();
      .size(8);
    }

    .img-wrapper {
      .col();
      .size(4);
    }
  }
}