@import "vars";
@import "smart-grid";


#X-2608{
  .lPage{
    &-section:nth-child(2){
      background-image: url('/image/catalog/lending-image/x2608/x2608-15.png');
      background-repeat: no-repeat;
      background-position: top right;
    }
    &-product-color{
      .color-product{
        &:first-child {
          background: linear-gradient(90deg, #fff 50%, orange 50%);
        }
        &:nth-child(2) {
          background: linear-gradient(90deg, #fff 50%, red 50%);
        }
        &:last-child {
          background: linear-gradient(90deg, #fff 50%, blue 50%);
        }

        &:not(:last-child) {
          margin-right: 40px;
        }
      }
    }
  }
}
