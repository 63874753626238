@import "vars";
@import "smart-grid";

#ocfilter-mobile {
  display: none;
  .md-block({ display: block; });

  .navbar-toggle {
    display: none;

    .md-block({ display: block !important; })
  }

  .navbar-header {
    float: none !important;
  }

  .navbar-default{
    background-color: #ffffff;
  }

  .panel{
    margin-bottom: 0;
  }
}

#ocfilter {
  &.panel{
    border-radius: 0;
  }

  .panel-heading {
    .md-block({ display: none; })
  }

  &.ocfilter-hide {
    display: none;
  }
  &.ocfilter-show {
    display: block;
  }

  .noUi-handle {
    box-shadow: none;
    //border: none;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid @dark;
    top: -15px;

    &:before, &:after {
      content: none;
    }
  }

  .noUi-connect{
    background: @sky;
  }

  .button-default{
    &:hover{
      box-shadow: 0 4px 5px rgba(0, 0, 0, 0.25);
    }
  }

  .ocfilter-option{
    .option-name{
      margin-bottom: 16px;
    }
  }

  .option-values{
    input[type="checkbox"]{
      width: 18px;
      height: 18px;
    }

    .noUi-horizontal{
      height: 5px;
    }
  }
}

