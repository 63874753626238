/*fonts*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&subset=cyrillic-ext");
@import url('https://fonts.googleapis.com/css?family=Oswald:400,700&display=swap');
// Regular 400
// Medium 500
// Semi-Bold 600
// Bold 700

/*colors*/
@dark: #171717;
@preHeaderBg: #4B4C4C;
@darkBlue: #223555;
@sky: #38A0F6;
@green: #18AF2E;
@blue: #135ACE;
@orange: #E2A429;
@terra: #FF423F;
@yellow: #EFE71B;
@red: #F8082D;
@lightPink: #FFE2DA;
@lightPurple: #E0CEEF;
@lightBlue: #C1E3FF;

@lightGrey: #F5F5F5;

@textBlack: #000;
@textGrey: #A5AEBE;
@textRed: #F8082D;

/*placeholder*/
@placeholderColor: #cecece;
input[type="text"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder{color:@placeholderColor}
input[type="text"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
input[type="number"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
textarea:-moz-placeholder{color:@placeholderColor}
input[type="text"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="number"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
textarea::-moz-placeholder{color:@placeholderColor}
input[type="text"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
textarea:-ms-input-placeholder{color:@placeholderColor}

/* em */
.fz(@value){
  font-size: @value / 16 * 1rem;
}

/* box-shadow */
.bx(@x, @y, @a, @color){
  box-shadow: @x @y @a @color;
}

