@import "vars";
@import "smart-grid";

.main-home {
  .wrapper-full {
    //padding-left: 0;
    //padding-right: 0;
  }
}

/****** OWL HOME ******/
.home-gtr {
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 64px;

  .lg-block({ margin-left: -20px; margin-right: -20px;});
  .sm-block({ margin-bottom: 40px;});
  .xs-block({ margin-bottom: 20px; margin-left: -16px; margin-right: -16px;})
}

/****** CATEGORY MODULE HOME ******/
.category-module {
  margin-bottom: 60px;

  .md-block({ margin-bottom: 40px;});
  .xs-block({ margin-bottom: 20px;});

  h3{
    display: none;
  }

  &-items {
    .row-flex();
  }

  &-item{
    .col();
    .size(4);
    .size-sm(12);

    background-color: @lightGrey;
    text-align: center;
    padding: 25px 25px 29px;
    transition: ease .5s;

    .sm-block({ margin-bottom: 20px; display: flex;});

    &:hover{
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
      transition: ease .5s;
    }

    a{
      color: @textBlack;
      .fz(24);
      font-weight: 700;
      display: block;

      .mdm-block({.fz(18)});
      .sm-block({ .fz(22);});
      .xxs-block({.fz(18)})
    }

    .img-wrapper{
      margin: 23px auto 0;
      max-width: 385px;

      .sm-block({ display: none;});
    }
  }
}