@import "vars";
@import "smart-grid";

.pre-header {
  background-color: @preHeaderBg;
  text-align: center;
  padding: 13px 0;

  &.light-coloured {
    border-bottom: 1px solid @textGrey;
  }

  & > a {
    color: #ffffff;
    .fz(14);
    margin-right: 46px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: .4px;

    .sm-block({ margin-right: 5%; });
    .to(480px, { margin-right: 2%; &:nth-child(3) {
      display: none;
    } });
  }

  .user-icon {
    width: 16px;
    height: 16px;
    fill: #fff;
    margin-right: 6px;
  }

  .account {
    display: inline-block;
    text-align: left;

    & > a {
      color: #ffffff;
      .fz(14);
      margin-right: 46px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: .4px;
    }

    &-text {
      text-transform: lowercase;
      position: relative;

      .sm-block({ display: none; });
    }
  }

  .dropdown-menu > li > a {
    width: 100%;
  }

  .dropdown-menu-left {
    border-radius: 0;
    background-color: @dark;
    top: 32px;
    border: 1px solid #fff;
    padding-top: 10px;

    .sm-block({ right: 0; left: auto; z-index: 4 });

    li {
      text-align: center;
      margin: 0 10px 8px;
    }

    a {
      color: #ffffff;
      border-bottom: 2px solid transparent;
      display: inline;
      padding: 0;

      &:hover {
        background: transparent;
        border-bottom: 2px solid @sky;
      }
    }
  }

  #search{
    margin-bottom: 0;
    z-index: 1;

    &-input{
      border: none;
      border-bottom: 1px solid transparent;
      padding: 5px 10px;
      -webkit-appearance: none;
      box-shadow: 0 1px 6px @textGrey;
      border-radius: 30px;
      width: 720px;
      box-sizing: border-box;

      &:focus{
        outline: none;
        border-bottom-color: @dark;
      }

      .mdm-block({ width: 620px;});
      .sm-block({ width: 480px;});
      .to(520px, { width: 320px;});
      .to(360px, { width: 300px;});
    }
  }

  .search{

    &-wrapper{
      display: inline-block;
      position: relative;
    }

    &-icon-item{
      position: relative;
      cursor: pointer;

      svg{
        width: 20px;
        height: 20px;
        fill: #fff;
      }
    }

    &-input-wrapper{
      display: none;
      position: absolute;
      top: 30px;
      right: 0;
      z-index: 5;

      .xs-block({ right: -22px;});
      .to(520px, { right: 0;});
      .to(480px, { right: -280%;});
      .to(360px, { right: -220%;});
    }
  }
}

.light-coloured {
  background-color: #fff;

  & > a {
    color: @textGrey;
  }

  .user-icon {
    fill: @textGrey;
  }

  .account-text {
    color: @textGrey;
  }

  .dropdown-menu-left {
    background-color: #fff;

    li {
      & > a {
        &:hover {
          color: @textBlack;
        }
      }
      a {
        color: @textBlack;
      }
    }
  }

  .search-icon-item{
    svg{
      fill: @textGrey;
    }
  }
}

header {
  background: @dark;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;

  .d-flex {
    align-items: center;
    justify-content: space-between;
  }
}

.logo-block {
  .md-block({ order: 1; })
}

.logo-gtr, .logo_gtr {
  fill: #fff;
  width: 50px;
  height: 50px;
}

.burger {

  @burgerColor: @dark;

  display: none;
  width: 40px;
  height: 40px;
  position: relative;
  align-items: center;

  .md-block({ display: flex; });

  &-item {
    width: 80%;
    height: 4px;
    background-color: @burgerColor;
    cursor: pointer;
    transition: all .2s;
    display: block;

    &:before, &:after {
      content: '';
      width: 100%;
      height: 4px;
      background-color: @burgerColor;
      cursor: pointer;
      transition: all .2s;
      display: block;
    }

    &:before { //1
      transform: translateY(-10px);
    }
    &:after { //3
      transform: translateY(7px);
    }
  }

  &_active {

    .burger-item {
      background: transparent;

      &:before {
        transform: translateY(0) rotate(45deg);
      }

      &:after {
        transform: translateY(-4px) rotate(-45deg);
      }
    }
  }
}

.dp {
  &:after {
    content: '...';
    display: block;
    color: #ffffff;
    .fz(24);
    letter-spacing: 3px;
    margin: 0;
    padding: 0;
    line-height: 0;
    text-align: center;
  }
}

#menu {
  width: 100%;
  padding: 0 20px;

  .md-block({ width: auto; padding: 0; });
}

.header {
  &-menu {
    color: #ffffff;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;

    .to(1200px, { justify-content: space-evenly; });
    .md-block({ display: none; });

    a, span {
      color: #ffffff;
      .fz(16);
      font-weight: 600;
      text-transform: uppercase;
      line-height: normal;

      .to(1200px, { .fz(14); });
    }

    span {
      cursor: pointer;

      .md-block({ display: none; })
    }

    &-drop {
      background-color: #fff;
      display: none;
      position: absolute;
      top: 86px;
      left: 0;
      z-index: 3;
      width: 100vw;
      text-align: center;
      box-shadow: 0 2px 4px rgba(0, 0, 0, .3);

      &-wrapper {
        max-width: 1440px;
        margin: 0 auto;
        background-color: #ffffff;
        display: flex;
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }

    &-item, &-info {
      list-style: none;
      padding: 0;
      color: @textBlack;
      margin-right: 55px;
      //white-space: nowrap;

      .to(1200px, { margin-right: 0; })
    }

    &-first-category {
      display: none;

      .md-block({ display: inline-block; })
    }
    /*active menu*/

    &.active-menu {
      display: block;
      position: absolute;
      top: 86px;
      left: 0;
      z-index: 3;
      padding: 10px;
      background-color: @dark;
      width: 100%;
      text-align: center;

      //todo сделать position: fixed mobile menu .on('scroll');

      .header-menu {
        &-item, &-info {
          margin-bottom: 20px;
        }

        &-item {
          &.dp:after {
            position: absolute;
            top: 28px;
            left: 48.5%;
            .fz(32);
            color: @sky;

            .to(480px, { left: 46%; });
          }
        }

        &-first-category {
          .fz(20);
          color: @sky;
        }

        &-drop {
          position: unset;
          display: block;
          box-shadow: none;
          width: auto;
          margin-top: 24px;

          &-wrapper {
            background-color: @dark;
            padding: 0;
          }

          .header {
            &-sub-menu {
              display: block;
              text-align: center;

              &-item {
                width: auto;
                margin: 0;
                padding: 0;
                display: block;

                a {
                  .fz(16);
                  color: #ffffff;
                  text-transform: uppercase;
                  font-weight: 600;

                  &:hover {
                    text-decoration: underline;
                  }
                }

                .img-wrapper {
                  display: none;
                }
                &.all-category {
                  display: none;
                }
              }
            }
          }
        }

        &-info {
          a {
            .fz(20);

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  &-sub-menu {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 0;

    &-item {
      box-sizing: border-box;
      list-style: none;
      text-align: center;
      width: calc(100% / 6 - 40px);
      margin-left: 20px;
      margin-right: 20px;
      padding-bottom: 20px;

      .from(1024px, { &:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
        transition: .3s;
      } });

      .img-wrapper {
        //margin: 25px auto 15px;
        margin: 10px auto 5px;

        .from(1200px, { width: 140px; height: 140px; });
      }

      a {
        //.fz(24);
        .fz(22);
        //font-weight: 700;
        text-transform: unset;
        color: @textBlack;
        //line-height: 38px;
        line-height: normal;
      }

      &.all-category {
        a {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 100%;
        }

        span {
          .fz(24);
          font-weight: 700;
          text-transform: unset;
          color: @textBlack;
          display: block;

          .to(1200px, { .fz(16) });
        }
      }

      .dot {
        @w-h: 5px;
        @color: @textBlack;

        width: @w-h;
        height: @w-h;
        background-color: @color;
        border-radius: 50%;
        display: block;
        position: relative;
        margin-top: 20px;

        &:after, &:before {
          content: '';
          width: @w-h;
          height: @w-h;
          background-color: @color;
          border-radius: 50%;
          display: inline-block;
          position: absolute;
          top: 0;
        }

        &:after {
          left: 10px;
        }
        &:before {
          left: -10px;
        }
      }
    }
  }

  &-shopping-cart {
    //margin: 0 10%;
    position: relative;

    .md-block({ order: 2; });

    a {
      font-weight: 600;
      color: #ffffff;

      &:hover {
        color: #ffffff;
      }
    }

    .cart-icon {
      fill: #fff;
      width: 40px;
      height: 40px;
      position: relative;
    }

    span {
      position: absolute;
      top: 7px;
      left: 14px;
      right: auto;
      display: block;
      width: 20px;
      height: 20px;
      text-align: center;
    }
  }
}

header.light-coloured {
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
  .logo-gtr, .logo_gtr {
    fill: @dark;
  }

  .header-menu {
    a, span {
      color: @dark;
    }

    &.active-menu {
      a, span {
        color: #fff;
      }
    }
  }

  .dp {
    &:after {
      color: @dark;
    }
  }

  .header-shopping-cart {
    .cart-icon {
      fill: @dark;
    }

    #cart-total {
      color: @dark;
    }
  }
}